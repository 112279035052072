var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        {
          key: _vm.joinMeetingMemberInfo.name,
          staticClass: "joinTheMeetingTips"
        },
        [
          _c("div", { staticClass: "nickname" }, [
            _vm._v("\n    " + _vm._s(_vm.joinMeetingMemberInfo.name) + "\n  ")
          ]),
          _vm._m(0),
          _vm._m(1)
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "joinIcon" }, [
      _c("i", { staticClass: "iconfont_Me icon-plus iconStyle" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "meetingIcon" }, [
      _c("i", { staticClass: "iconfont_Me icon-video-fill iconStyle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }