<template>
  <div
    class="roomBox"
    @mousemove="mousemoveForoperate"
    @mousedown="mousedownForoperate"
  >
    <div
      class="leftBox"
      :style="{
        width: `${leftBoxWidth}px`,
        transform: `translate3d(${leftBoxDisplacement},0,0)`,
      }"
    >
      <RoomList
        :sdkReady="sdkReady"
        :listStatus="listStatus"
        :screenSizeForMeeting="screenSizeForMeeting"
        @changeMemberList="changeMemberList"
      ></RoomList>
    </div>
    <div
      class="rightBox"
      :style="{
        paddingLeft: rightBoxPadding,
      }"
    >
      <DisplayPanel
        :screenSizeForMeeting="screenSizeForMeeting"
        :sdkReady="sdkReady"
      ></DisplayPanel>
    </div>
  </div>
</template>
<script>
import RoomList from "./leftBox/roomList";
import DisplayPanel from "./rightBox";
export default {
  components: {
    RoomList,
    DisplayPanel,
  },
  props: {
    // 窗口依据不同屏幕宽度的状态
    screenSizeForMeeting: {
      type: String,
      default: "largeScreen",
    },
    // 监听音视频SDK是否准备完毕
    sdkReady: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // 左侧列表宽度
    leftBoxWidth() {
      let leftBoxWidth = 0;
      if (
        this.$store.state.multimedia_meetingmode.meetingWindowStyle ===
        "normalStyle"
      ) {
        leftBoxWidth = this.screenSizeForMeeting === "largeScreen" ? 110 : 100;
      } else if (
        this.$store.state.multimedia_meetingmode.meetingWindowStyle ===
        "largestStyle"
      ) {
        leftBoxWidth = 218;
      }
      return leftBoxWidth;
    },
    // 左侧列表位移距离
    leftBoxDisplacement() {
      return this.listStatus ? "0px" : `-${this.leftBoxWidth}px`;
    },
    // 右侧展示区域内边距长度
    rightBoxPadding() {
      return this.listStatus ? `${this.leftBoxWidth}px` : "0px";
    },
  },
  data() {
    return {
      // 左侧列表隐藏状态
      listStatus: true,
      // 鼠标移动需要的定时器
      mousemoveTimer: null,
    };
  },
  // 组件实例销毁前
  beforeDestroy() {
    if (this.mousemoveTimer) {
      clearTimeout(this.mousemoveTimer);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.mousemoveForoperate();
    });
  },
  methods: {
    // 根据鼠标移动展示操作界面
    mousemoveForoperate() {
      // 鼠标移动才会展示操作按钮，鼠标停止移动5S过后就隐藏操作按钮
      this.$store.commit("setNeedToHideOperationButton", false);
      clearTimeout(this.mousemoveTimer);
      this.mousemoveTimer = setTimeout(() => {
        this.$store.commit("setNeedToHideOperationButton", true);
        this.$store.commit("setMeetingTooltip", false);
      }, 3000);
    },
    // 鼠标按下隐藏meetingTooltip
    mousedownForoperate() {
      this.$store.commit("setMeetingTooltip", false);
    },
    // 改变左侧列表展示状态
    changeMemberList(listStatus) {
      this.listStatus = !listStatus;
    },
  },
};
</script>
<style lang="stylus" scoped>
.roomBox
  width 100%;
  height 100%;
  position relative;
  .leftBox
    height 100%;
    flex-shrink 0;
    background rgba(0,0,0,0.8);
    transition transform 0.3s;
    position absolute;
    z-index 2;
  .rightBox
    width 100%;
    height 100%;
    position relative;
    box-sizing border-box;
    transition padding 0.3s;
</style>
