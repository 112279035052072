<template>
  <div
    class="memberItem"
    :class="itemStyle"
    @click.stop="enlargeTheVideo"
    :style="{
      border: membersOnTheHomeScreen === null ? '2px solid #52BD68' : 'none',
    }"
  >
    <div
      class="memberShow"
      ref="localVideo"
      id="localVideo"
      :data-uid="localInfo.uid"
    >
      <el-image
        class="memberImage"
        :src="formartImageUrl(localInfo.profilePhoto)"
        :fit="'cover'"
        v-if="!needShowVideo"
      >
      </el-image>
    </div>
    <div class="initiatorIcon">
      <el-image
        style="width: 100%; height: 100%; display: block"
        :src="
          require('../../../../assets/images/newMeetingMultiplayer/initiatorIcon.png')
        "
        v-if="localInfo.uid === meetingCreatorId"
        :fit="'fill'"
      ></el-image>
    </div>
    <!-- <div class="enlargeIcon" @click.stop="enlargeTheVideo"></div> -->
    <!-- 会员标识 -->
    <div v-if="localInfo.vipStatus" class="vipIcon">
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('paidMember')"
        placement="bottom"
        popper-class="me-tooltip-effect"
      >
        <VipIcon
          :vipStatus="localInfo.vipStatus"
          :iconWidth="14"
          :iconHeight="14"
          :id="localInfo.uid"
        />
      </el-tooltip>
    </div>
    <div class="memberInfo">
      <div class="microphoneBox">
        <MonitorSelfAudioStatus
          :volumeLevel="$store.state.multimedia_meetingmode.meetingVolume"
          v-if="memberMicrophoneStatus"
        ></MonitorSelfAudioStatus>
        <img
          v-else
          class="microphoneCloseStyle"
          :src="
            require('../../../../assets/images/newMeetingMultiplayer/microphoneCloseIcon.png')
          "
        />
      </div>
      <div
        class="memberName"
        :title="localInfo.name"
        :class="localInfo.vipStatus ? 'xvipName' : ''"
      >
        {{ localInfo.name }}
      </div>
    </div>
  </div>
</template>
<script>
import * as RESTApi from "@/api/rest";
import MonitorSelfAudioStatus from "../widget/monitorSelfAudioStatus";
import VipIcon from "@/newVersion/components/icons/IconVip.vue";
import {
  playLocalStream,
  stopSharingScreen,
  startSharingScreen,
} from "../coreOperations/local";
export default {
  components: {
    MonitorSelfAudioStatus,
    VipIcon,
  },
  props: {
    // 窗口宽度状态，小窗口状态时显示为
    screenSizeForMeeting: {
      type: String,
      default: "largeScreen",
    },
    // 成员基本信息
    localInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    // 监听是否可以展示视频
    needShowVideo: {
      handler(val, old) {
        // 如果本地盒子为打开视频窗口页面，则说明需要在这里播放本地视频了
        if (val && val !== old) {
          this.$nextTick(async () => {
            // 如果是处于分享状态，可以直接切换元素
            await playLocalStream(this.$refs.localVideo);
          });
        }
      },
      deep: true,
    },
    // 监听屏幕分享开启的状态
    isSharingScreen: {
      handler(val, old) {
        // 如果当前主屏播放的不是本地流才会执行后续操作
        if (this.membersOnTheHomeScreen === null) {
          return;
        }
        // 如果关闭屏幕分享，并且用户没有在主屏播放
        if (val !== old && val == false) {
          stopSharingScreen(this.$refs.localVideo)
            .then(async (e) => {
              // 展示摄像头按钮
              this.$store.commit("setShowCameraStatusButton", true);
              let params = {
                meetingType: "groupChat",
                subjectId: this.$store.state.multimedia_meetingmode
                  .meetingGroupId,
                openCamera: this.$store.state.multimedia_meetingmode
                  .userSetCameraStatus,
              };
              // 屏幕分享关闭成功后发起请求
              let result = await RESTApi.turnOffShareScreen(params);
              if (result.code !== 200) {
                this.$message({
                  type: "error",
                  message: result.message,
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else if (val !== old && val == true) {
          startSharingScreen(this.$refs.localVideo)
            .then(async (e) => {
              // 隐藏摄像头按钮
              this.$store.commit("setShowCameraStatusButton", false);
              // 开始分享就最小化
              let params = {
                meetingType: "groupChat",
                subjectId: this.$store.state.multimedia_meetingmode
                  .meetingGroupId,
              };
              // 屏幕分享打开成功后发起请求
              let result = await RESTApi.shareScreen(params);
              if (result.code !== 200) {
                this.$message({
                  type: "error",
                  message: result.message,
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      },
      deep: true,
    },
  },
  computed: {
    // 本地用户是否进入分享屏幕状态
    isSharingScreen() {
      return this.$store.state.multimedia_meetingmode.isSharingScreen;
    },
    // 是否在此处展示视频
    needShowVideo() {
      let needShowVideo = false;
      // 当这个盒子是用户自己的手，并且主屏展示的不是用户自己，则需要在这个盒子播放用户自己的本地视频
      needShowVideo =
        (this.membersOnTheHomeScreen !== null &&
          (this.$store.state.multimedia_meetingmode.userSetCameraStatus ||
            this.isSharingScreen)) ||
        (this.$store.state.multimedia_meetingmode.userSetCameraStatus &&
          this.isSharingScreen);
      return needShowVideo;
    },
    // 当前在主屏展示的人员ID
    membersOnTheHomeScreen() {
      return this.$store.state.multimedia_meetingmode.membersOnTheHomeScreen;
    },
    // 会议发起者ID
    meetingCreatorId() {
      return this.$store.state.multimedia_meetingmode.meetingCreatorId;
    },
    // 是否展示麦克风图标
    memberMicrophoneStatus() {
      return this.$store.state.multimedia_meetingmode.userSetMicrophoneStatus;
    },
    // 根据不同状态展示不同样式(itemStyle1：会议室为窄屏模式时展示的样式，itemStyle2：会议室为正常模式时展示的样式，itemStyle3：会议室为最大展开状态时的样式)
    itemStyle() {
      let itemStyle = "";
      if (
        this.$store.state.multimedia_meetingmode.meetingWindowStyle ===
        "normalStyle"
      ) {
        itemStyle =
          this.screenSizeForMeeting === "largeScreen"
            ? "itemStyle2"
            : "itemStyle1";
      } else if (
        this.$store.state.multimedia_meetingmode.meetingWindowStyle ===
        "largestStyle"
      ) {
        itemStyle = "itemStyle3";
      }
      return itemStyle;
    },
  },
  methods: {
    // 放大视频
    enlargeTheVideo() {
      if (this.membersOnTheHomeScreen !== null) {
        // 如果是用户自己，就将主屏展示设置为null，如果是其他成员，就设置为他的ID
        this.$store.commit("setMembersOnTheHomeScreen", null);
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.itemStyle1 {
  height: 74px;

  .initiatorIcon {
    width: 12px;
    height: 12px;
    overflow: hidden;
    top: 2px;
    left: 4px;
  }

  .enlargeIcon {
    top: 4px;
    right: 4px;
    width: 10px;
    height: 10px;
    background: url('../../../../assets/images/newMeetingMultiplayer/selectIcon.png') no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
  .vipIcon{
    top: -2px;
    right: 4px;
    cursor: pointer;
  }

  .memberInfo {
    height: 18px;
    border-radius: 9px;
    max-width: 64px;
    left: 5px;
    bottom: 6px;
    padding: 0 6px 0 24px;
    // padding-left:23px;
    .microphoneBox {
       width:22px;
       height: 22px;
      .microphoneCloseStyle {
        width: 14px;
        height: 14px;
        display: block;
      }
    }

    .memberName {
      flex: 1;
      font-size: 10px;
      color: #FFFFFF;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.itemStyle2 {
  height: 84px;

  .initiatorIcon {
    width: 14px;
    height: 14px;
    overflow: hidden;
    top: 3px;
    left: 5px;
  }

  .enlargeIcon {
    top: 6px;
    right: 6px;
    width: 12px;
    height: 12px;
    background: url('../../../../assets/images/newMeetingMultiplayer/selectIcon.png') no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }

  .vipIcon{
    top: 1px;
    right: 4px;
    cursor: pointer;
  }

  .memberInfo {
    height: 18px;
    border-radius: 9px;
    max-width: 74px;
    left: 5px;
    bottom: 6px;
    padding: 0 5px 0 27px;
    .microphoneBox {
      width:25px;
      height: 25px;
      .microphoneCloseStyle {
        width: 15px;
        height: 15px;
        display: block;
      }
    }

    .memberName {
      flex: 1;
      font-size: 10px;
      color: #FFFFFF;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.itemStyle3 {
  height: 120px;

  .initiatorIcon {
    width: 18px;
    height: 18px;
    overflow: hidden;
    top: 9px;
    left: 8px;
  }

  .enlargeIcon {
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    background: url('../../../../assets/images/newMeetingMultiplayer/selectIcon_large.png') no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }

  .vipIcon{
    top: 7px;
    right: 7px;
    // left: 7px;
    cursor: pointer;
    svg{
      width 20px
      height 20px
    }
  }

  .memberInfo {
    height: 22px;
    border-radius: 11px;
    max-width: 158px;
    left: 10px;
    bottom: 7px;
    padding: 0 9px 0 30px;
    .microphoneBox {
      width:28px;
      height: 28px;
      .microphoneCloseStyle {
        width: 16px;
        height: 16px;
        display: block;
      }
    }

    .memberName {
      flex: 1;
      font-size: 10px;
      color: #FFFFFF;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.memberItem {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background: #0F0F0F;

  .memberShow {
    width: 100%;
    height: 100%;

    .memberImage {
      width: 100%;
      height: 100%;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .memeberVideo {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .initiatorIcon {
    position: absolute;
    z-index: 2;
  }

  .enlargeIcon {
    position: absolute;
    z-index: 2;
  }

  .vipIcon{
    position: absolute;
    z-index: 2;
  }

  .memberInfo {
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    z-index: 2;
    // overflow: hidden;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // padding-left: 23px;
    .microphoneBox {
      // width:22px;
      // height: 22px;
      border-radius: 50%;
      left:0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.8);
      position: absolute;
      border: 1px solid #4d4d4d;
      // height: 100%;
      flex-shrink: 0;
      // padding-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
