var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$store.state.multimedia_meetingmode.meetingWindowStatus
    ? _c(
        "div",
        {
          directives: [{ name: "customDrag", rawName: "v-customDrag" }],
          ref: "meetingRoom",
          staticClass: "meetingRoom",
          class:
            _vm.$store.state.multimedia_meetingmode.meetingWindowStyle ===
            "largestStyle"
              ? "largestStyle"
              : "",
          style: {
            top: _vm.windowTopCoordinate,
            left: _vm.windowLeftCoordinate,
            width: _vm.windowWidth,
            height: _vm.windowHeight,
            zIndex:
              _vm.$store.state.multimedia_meetingmode.meetingWindowStyle ===
              "smallStyle"
                ? 2001
                : 2000
          }
        },
        [
          _c(
            "div",
            { staticClass: "dragOperationArea" },
            [
              !this.$store.state.multimedia_meetingmode.localStreamIsReady &&
              !_vm.OVERTIME
                ? _c(
                    "div",
                    {
                      staticClass: "loading-box",
                      staticStyle: {
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center",
                        position: "relative"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            top: "14px",
                            right: "14px",
                            cursor: "pointer"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.closeThisWindow.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "el-icon-close",
                            staticStyle: { color: "#fff" }
                          })
                        ]
                      ),
                      _vm._m(0)
                    ]
                  )
                : _vm._e(),
              _vm.OVERTIME
                ? _c("div", { staticClass: "loading-failed-box" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          top: "14px",
                          right: "14px",
                          cursor: "pointer"
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.closeThisWindow.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "el-icon-close",
                          staticStyle: { color: "#fff" }
                        })
                      ]
                    ),
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/im/loadingFailed.png"),
                        alt: ""
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "reload-btn", on: { click: _vm.refresh } },
                      [_vm._v(_vm._s(_vm.$t("reload")))]
                    )
                  ])
                : _vm._e(),
              _c("MeetingRoom", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.$store.state.multimedia_meetingmode
                        .meetingWindowStyle !== "smallStyle",
                    expression:
                      "\n        $store.state.multimedia_meetingmode.meetingWindowStyle !==\n          'smallStyle'\n      "
                  }
                ],
                attrs: {
                  sdkReady: _vm.sdkReady,
                  screenSizeForMeeting: _vm.screenSizeForMeeting
                }
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.$store.state.multimedia_meetingmode
                          .meetingWindowStyle === "smallStyle",
                      expression:
                        "\n        $store.state.multimedia_meetingmode.meetingWindowStyle ===\n          'smallStyle'\n      "
                    }
                  ],
                  staticClass: "miniShow",
                  on: {
                    mouseup: _vm.gomouseup,
                    mousedown: function($event) {
                      return _vm.gomousedown($event)
                    },
                    mousemove: _vm.gomousemove
                  }
                },
                [
                  !_vm.$store.state.multimedia_meetingmode.userSetCameraStatus
                    ? _c("div", { staticClass: "hearder" }, [
                        _c(
                          "div",
                          {
                            ref: "localVideo",
                            staticClass: "memberShow",
                            attrs: { "data-uid": _vm.localInfo.uid }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "memberImageBox" },
                              [
                                _c("el-image", {
                                  staticClass: "memberImage",
                                  attrs: {
                                    draggable: "false",
                                    src: _vm.formartImageUrl(
                                      _vm.localInfo.profilePhoto
                                    ),
                                    fit: "cover"
                                  }
                                }),
                                _c("div", { staticClass: "isInitiator" })
                              ],
                              1
                            ),
                            _c("div", { staticClass: "memberText" }, [
                              _c(
                                "div",
                                { staticClass: "microphoneBox" },
                                [
                                  _vm.memberMicrophoneStatus
                                    ? _c("MonitorSelfAudioStatus", {
                                        attrs: {
                                          volumeLevel:
                                            _vm.$store.state
                                              .multimedia_meetingmode
                                              .meetingVolume
                                        }
                                      })
                                    : _c("img", {
                                        staticClass: "microphoneCloseStyle",
                                        attrs: {
                                          src: require("../../../assets/images/newMeetingMultiplayer/microphoneCloseIcon.png")
                                        }
                                      })
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "textStyle",
                                  class: _vm.localInfo.vipStatus
                                    ? "cvipName"
                                    : ""
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.localInfo.name) +
                                      "\n              "
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: _vm.$t("paidMember"),
                                        placement: "bottom",
                                        "popper-class": "me-tooltip-effect"
                                      }
                                    },
                                    [
                                      _c("VipIcon", {
                                        staticClass: "icon",
                                        staticStyle: {
                                          cursor: "pointer",
                                          "margin-left": "1px",
                                          "margin-top": "1px"
                                        },
                                        attrs: {
                                          vipStatus: _vm.localInfo.vipStatus,
                                          iconWidth: 14,
                                          iconHeight: 14,
                                          id: _vm.localInfo.id
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ])
                    : _vm._e(),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.$store.state.multimedia_meetingmode
                            .userSetCameraStatus,
                        expression:
                          "$store.state.multimedia_meetingmode.userSetCameraStatus"
                      }
                    ],
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { id: "miniScreen" }
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.$store.state.multimedia_meetingmode
                              .userSetCameraStatus,
                          expression:
                            "$store.state.multimedia_meetingmode.userSetCameraStatus"
                        }
                      ],
                      staticClass: "memberText",
                      staticStyle: {
                        position: "absolute",
                        left: "15px",
                        bottom: "15px"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "microphoneBox" },
                        [
                          _vm.memberMicrophoneStatus
                            ? _c("MonitorSelfAudioStatus", {
                                attrs: {
                                  volumeLevel:
                                    _vm.$store.state.multimedia_meetingmode
                                      .meetingVolume
                                }
                              })
                            : _c("img", {
                                staticClass: "microphoneCloseStyle",
                                attrs: {
                                  src: require("../../../assets/images/newMeetingMultiplayer/microphoneCloseIcon.png")
                                }
                              })
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "textStyle",
                          class: _vm.localInfo.vipStatus ? "cvipName" : ""
                        },
                        [
                          _vm._v(_vm._s(_vm.localInfo.name) + "\n          "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: _vm.$t("paidMember"),
                                placement: "bottom",
                                "popper-class": "me-tooltip-effect"
                              }
                            },
                            [
                              _c("VipIcon", {
                                staticClass: "icon",
                                staticStyle: {
                                  cursor: "pointer",
                                  "margin-left": "1px"
                                },
                                attrs: {
                                  vipStatus: _vm.localInfo.vipStatus,
                                  iconWidth: 14,
                                  iconHeight: 14,
                                  id: _vm.localInfo.id
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "commontiming switchSizeBox" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t("reduction"),
                            placement: "bottom-start"
                          }
                        },
                        [
                          _c(
                            "i",
                            [
                              _c("SizeButton", {
                                attrs: { buttonType: "full", color: "white" }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c("span", { staticClass: "line" }),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t("maximize"),
                            placement: "bottom-start"
                          }
                        },
                        [
                          _c(
                            "i",
                            {
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.hanging.apply(null, arguments)
                                }
                              }
                            },
                            [_c("SizeButton", { attrs: { color: "white" } })],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "outer-box Rotation",
        staticStyle: {
          width: "38px",
          height: "38px",
          background: "#fff",
          "border-radius": "50%",
          "box-shadow": "0px 3px 6px 0px rgba(0, 0, 0, 0.16)",
          display: "flex",
          "justify-content": "center",
          "align-items": "center",
          "font-size": "22px",
          color: "#b889d2",
          animation: "rotation 6s linear infinite"
        }
      },
      [_c("i", { staticClass: "el-icon-refresh-left Rotation" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }