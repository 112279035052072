<template>
  <div class="displayPanel">
    <div class="commontiming timingTextBox">
      <div class="liveStatus">
        <span class="inner"></span>
      </div>
      <span class="sharingScreenStatus"></span>{{ meetingTimingText }}
    </div>
    <!-- 刷新按钮 -->
    <el-tooltip
      class="item"
      effect="dark"
      :content="$t('refreshMeeting')"
      placement="bottom"
    >
      <div class="commontiming refresh" @click="refresh">
        <i class="el-icon-refresh-right"></i>
      </div>
    </el-tooltip>
    <!-- 5.4.0放大与缩小操作 -->
    <div class="commontiming switchSizeBox">
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('minimize')"
        placement="bottom"
      >
        <i class="noDrag" @click.stop="hanging">
          <SizeButton :buttonType="'minimize'" :color="'white'"></SizeButton>
        </i>
      </el-tooltip>
      <span class="line"></span>
      <el-tooltip
        class="item"
        effect="dark"
        :content="
          $store.state.multimedia_meetingmode.meetingWindowStyle ==
          'largestStyle'
            ? $t('reduction')
            : $t('maximize')
        "
        placement="bottom"
      >
        <i class="noDrag" @click="changeSize">
          <SizeButton
            :buttonType="
              $store.state.multimedia_meetingmode.meetingWindowStyle ==
              'largestStyle'
                ? 'full'
                : 'normalcy'
            "
            :color="'white'"
          ></SizeButton>
        </i>
      </el-tooltip>
    </div>
    <!-- 房间ID -->
    <div
      class="commontiming roomIdStyle"
      :class="
        $store.state.multimedia_meetingmode.meetingWindowStyle ===
        'largestStyle'
          ? 'largestStyle'
          : screenSizeForMeeting
      "
    >
      <div class="isInMeeting_box"></div>
      {{ $store.state.multimedia_meetingmode.meetingRoomId || meetingRoomId }}
    </div>

    <!-- 聊天列表 -->
    <!-- <div
      class="meeting_chat"
      ref="meeting_chat"
      v-if="
        $store.state.multimedia_meetingmode.meetingWindowStyle ==
          'largestStyle' && $store.state.multimedia_meetingmode.showChatStatus
      "
    > -->
    <div class="meeting_chat" ref="meeting_chat" v-if="false">
      <div
        v-for="(message, index) in $store.state.multimedia_meetingmode
          .messageAll"
        :key="message.id + index"
        :class="message.id + index"
        :ref="message.sendTime + index"
      >
        <div v-if="message.type == 'text'">
          <div class="chat-sender">
            {{ message.fromUser.displayName }}
            {{ _parseTime(message.sendTime) }}
          </div>
          <div class="chat-text">
            <div class="link_style">
              <!-- {{ message.msgs }} -->
              <div
                v-if="message.text"
                v-html="
                  $emoReplaceToImage(message.text).replace(/\r\n|\n/gi, '<br/>')
                "
                class="pd"
              ></div>
              <div v-else class="pd" v-html="message.text"></div>
              <!-- 如果有翻译，这显示翻译 -->
              <template v-if="message.msgs">
                <div v-for="item in message.msgs.msgs" :key="item.id">
                  <p
                    class="translateMsg pd"
                    v-if="
                      message.msgs.sourceLanguage != item.language &&
                        item.language == imSelectCode
                    "
                  >
                    {{ item.translateMsg }}
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
        <!-- 图片 -->
        <div v-if="message.type == 'image'">
          <div class="chat-sender">
            {{ message.fromUser.displayName }}
            {{ _parseTime(message.sendTime) }}
          </div>
          <div class="image-style mb20">
            <div class="me-thumb">
              <el-image
                style="width: auto"
                :src="message.url"
                :preview-src-list="[message.url]"
              >
              </el-image>
            </div>
          </div>
        </div>
        <!-- 视频 -->
        <div v-if="message.type == 'video' && message.url">
          <div class="chat-sender">
            {{ message.fromUser.displayName }}
            {{ _parseTime(message.sendTime) }}
          </div>
          <div
            class="mb20 flex flex-end"
            style="margin-left: auto"
            @click.stop="play(message)"
          >
            <el-video
              style="height: 100%; border-radius: 6px"
              :src="message.url"
              :alert-title="message.fizeName"
              :noPlayVideo="true"
            />
          </div>
        </div>
        <!-- 语音 -->
        <div v-if="message.type == 'voice'">
          <div class="chat-sender">
            {{ message.fromUser.displayName }}
            {{ _parseTime(message.sendTime) }}
          </div>
          <div
            class="imelink-message-voice chat-voice flex flex-end"
            @click.stop="play(message)"
          >
            <div class="chat_style flex j-c a-c">
              <el-progress
                type="circle"
                class="imelink-message-voice__progress"
                :percentage="
                  message.percentage > 100 ? 100 : message.percentage
                "
                color="#33cc66"
                :stroke-width="2"
                :width="30"
                :show-text="false"
              />
              <i class="imelink-message-voice__icon el-icon--play" />
              <audio preload="auto" :src="message.url" ref="voice" />
              <div class="imelink-message-voice__duration">
                {{ message.duration }}''
              </div>
              <div
                class="imelink-message-voice__speechConvertMessage"
                v-if="message.msgs"
              >
                {{ message.msgs.speechConvertMessage }}
              </div>
            </div>
          </div>
        </div>
        <!-- 文件 -->
        <div v-if="message.type == 'file'">
          <div class="chat-sender">
            {{ message.fromUser.displayName }}
            {{ _parseTime(message.sendTime) }}
          </div>
          <div class="flex flex-end">
            <!-- 单独处理文件格式 -->
            <div
              class="file--box flex mb20"
              @click="handleContentClick(message)"
              v-if="
                formartNewFileIcon(nameSfx(message.fileName)).indexOf(
                  'file.'
                ) !== 0
              "
            >
              <div class="imelink-message-file__icon">
                <el-image
                  class="fileIcon"
                  :src="formartNewFileIcon(nameSfx(message.fileName))"
                >
                </el-image>
              </div>
              <div class="imelink-message-file__content">
                <div class="imelink-message-file__name">
                  {{ message.fileName }}
                </div>
                <div class="imelink-message-file__foot imelink__foot">
                  <small>{{ toBytesSize(message.fileSize) }}</small>
                </div>
              </div>
            </div>
            <!-- 其他文件格式 -->
            <div v-else class="file--box flex mb20">
              <div class="imelink-message-file__icon">
                <i class="el-icon-folder" />
                <b>{{ nameSfx(message.fileName) }}</b>
              </div>
              <div class="imelink-message-file__content">
                <div class="imelink-message-file__name">
                  {{ message.fileName }}
                </div>
                <div class="imelink-message-file__foot imelink__foot">
                  <small>{{
                    isNaN(toBytesSize(message.fileSize))
                      ? "0 KB"
                      : toBytesSize(message.fileSize)
                  }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="commonDisplayStyle videoDisplayBox"
      id="videoDisplayBox"
      ref="homeScreenElement"
      v-show="needVideoShow"
    ></div>
    <div class="commonDisplayStyle memberDisplayBox" v-show="!needVideoShow">
      <div
        class="memberInfoBox"
        :style="{
          marginTop: `${memeberMarginTop}px`,
        }"
      >
        <div
          class="memberImageBox"
          :style="{
            width: `${memeberImageSize}px`,
            height: `${memeberImageSize}px`,
          }"
        >
          <div class="imageBox">
            <el-image
              style="width: 100%; height: 100%"
              :src="formartImageUrl(homeScreenUserInfo.profilePhoto)"
              :fit="'cover'"
            ></el-image>
          </div>

          <div
            :class="
              meetingWindowStyle == 'largestStyle'
                ? 'isInitiator_largest'
                : 'isInitiator'
            "
            v-if="homeScreenUserInfo.uid === meetingCreatorId"
          ></div>
        </div>
        <div class="memberNameBox">
          <div
            class="memberName"
            style="display: flex;align-items: center;"
            :class="homeScreenUserInfo.vipStatus ? 'xvipName' : ''"
          >
            {{ homeScreenUserInfo.name }}
            <!-- VIP绿钻标志 -->
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('paidMember')"
              placement="bottom"
              popper-class="me-tooltip-effect"
            >
              <VipIcon
                class="icon"
                style="margin-left: 1px"
                :vipStatus="homeScreenUserInfo.vipStatus"
                :iconWidth="14"
                :iconHeight="14"
                :id="homeScreenUserInfo.uid"
              />
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div
      class="joinTheMeetingTipsBox"
      v-if="joinMeetingMemberTips.length !== 0"
    >
      <transition-group name="joinTheMeetingItem" tag="div">
        <JoinTheMeetingTips
          v-for="(item, inx) in joinMeetingMemberTips"
          :key="item.name + inx"
          :joinMeetingMemberInfo="item"
          @deleteJoinTips="deleteJoinTips"
        ></JoinTheMeetingTips>
      </transition-group>
    </div>
    <!-- 这里加了v-show，确保sdk准备完成后，再进行操作，避免某些开启音视频无效的情况 -->
    <OperatingBox
      v-show="sdkReady"
      :screenSizeForMeeting="screenSizeForMeeting"
    ></OperatingBox>
    <div class="showSharingMask" v-if="showSharingMask"></div>
  </div>
</template>
<script>
import OperatingBox from "./operatingBox";
import JoinTheMeetingTips from "../widget/joinTheMeetingTips";
import VipIcon from "@/newVersion/components/icons/IconVip";
import {
  initLocalClient,
  playLocalStream,
  hangUpTheCall,
} from "../coreOperations/local";
import { playOtherMemberVideo } from "../coreOperations/client";
import { toBytesSize, parseTime, downloadFile, env } from "@/utils";
import * as RESTApi from "@/api/rest";
import SizeButton from "@/newVersion/components/im_size_button";
export default {
  props: {
    screenSizeForMeeting: {
      type: String,
      default: "largeScreen",
    },
    // 监听音视频SDK是否准备完毕
    sdkReady: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    OperatingBox,
    JoinTheMeetingTips,
    VipIcon,
    SizeButton,
  },
  watch: {
    // 监听加入会议的人员提示
    "joinMeetingMemberTips.length": {
      handler(val, old) {
        if (val && val !== old && val > 4) {
          let joinMeetingMemberTips = this.joinMeetingMemberTips.splice(
            this.joinMeetingMemberTips.length - 3,
            this.joinMeetingMemberTips.length - 1
          );
          this.$store.commit("setJoinMeetingMemberTips", joinMeetingMemberTips);
        }
      },
      deep: true,
    },
    "$store.state.multimedia_meetingmode.messageAll": {
      handler(newly, oldly) {
        if (newly) {
          this.imSelectCode = localStorage.getItem("imSelectCode") || 1;
          this.scrollToBottom();
        }
      },
      deep: true,
    },
    // 监听音视频SDK是否准备完毕
    sdkReady: {
      handler(val, old) {
        if (val && val !== old) {
          this.$nextTick(() => {
            this.initHomeScreenPlayer();
          });
        }
      },
      deep: true,
    },
    // 监听需要在主屏幕展示视频的用户ID变化
    membersOnTheHomeScreen: {
      handler(val, old) {
        this.$nextTick(async () => {
          if (val != old && val != null) {
            this.playOtherMemberVideo(val);
          } else if (val != old && val == null && this.playVideoOrScreen) {
            await playLocalStream(this.$refs.homeScreenElement);
          }
        });
      },
      deep: true,
    },
  },
  data() {
    return {
      // 帧函数标记
      timer: null,
      status: "pause",
      percentage: 0,
      imSelectCode: 1,
    };
  },
  computed: {
    toBytesSize() {
      return toBytesSize;
    },
    meetingRoomId() {
      return localStorage.getItem("meetingRoomId");
    },
    // 播放本地视频还是分享屏幕
    playVideoOrScreen() {
      return (
        this.$store.state.multimedia_meetingmode.userSetCameraStatus ||
        this.isSharingScreen
      );
    },
    // 窗口当前的大小
    meetingWindowStyle() {
      return this.$store.state.multimedia_meetingmode.meetingWindowStyle;
    },
    // 是否开启屏幕共享
    isSharingScreen() {
      return this.$store.state.multimedia_meetingmode.isSharingScreen;
    },
    // 刚刚加入会议的人员的提示队列
    joinMeetingMemberTips() {
      return this.$store.state.multimedia_meetingmode.joinMeetingMemberTips;
    },
    // 是否需要在大屏幕本地分享视频时展示半透明遮罩层
    showSharingMask() {
      return (
        this.membersOnTheHomeScreen === null &&
        this.$store.state.multimedia_meetingmode.isSharingScreen
      );
    },
    // 是否展示视频界面
    needVideoShow() {
      let needVideoShow = false;
      // 如果主屏展示的是本地用户，则判断是否开启摄像头或者分享屏幕
      if (this.membersOnTheHomeScreen === null) {
        needVideoShow =
          this.$store.state.multimedia_meetingmode.userSetCameraStatus ||
          this.$store.state.multimedia_meetingmode.isSharingScreen;
        // 如果展示的是成员，并且有他的流对象
      } else if (
        this.meetingRemoteStreams.length !== 0 &&
        this.meetingRemoteStreams.filter(
          (item) => item.getId() === this.membersOnTheHomeScreen
        ).length !== 0
      ) {
        let needShowMember = this.meetingRemoteStreams.filter(
          (item) => item.getId() === this.membersOnTheHomeScreen
        )[0];
        needVideoShow = needShowMember.video || needShowMember.screen;
      }
      return needVideoShow;
    },
    // 会议计时
    meetingTimingText() {
      return this.$store.state.multimedia_meetingmode.meetingTimingText;
    },
    // 没开视频的用户展示的头像相对于顶部的距离
    memeberMarginTop() {
      let memeberMarginTop = 0;
      if (
        this.$store.state.multimedia_meetingmode.meetingWindowStyle ===
        "normalStyle"
      ) {
        memeberMarginTop =
          this.screenSizeForMeeting === "largeScreen" ? 126 : 175;
      } else if (
        this.$store.state.multimedia_meetingmode.meetingWindowStyle ===
        "largestStyle"
      ) {
        memeberMarginTop = 230;
      }
      return memeberMarginTop;
    },
    // 用户展示头像的宽度
    memeberImageSize() {
      let memeberImageSize = 0;
      if (
        this.$store.state.multimedia_meetingmode.meetingWindowStyle ===
        "normalStyle"
      ) {
        memeberImageSize =
          this.screenSizeForMeeting === "largeScreen" ? 98 : 86;
      } else if (
        this.$store.state.multimedia_meetingmode.meetingWindowStyle ===
        "largestStyle"
      ) {
        memeberImageSize = 128;
      }
      return memeberImageSize;
    },
    // 当前主屏的dom对象
    homeScreenElement() {
      return this.$store.state.multimedia_meetingmode.homeScreenElement;
    },
    // 会议发起者ID
    meetingCreatorId() {
      return this.$store.state.multimedia_meetingmode.meetingCreatorId;
    },
    // 当前参加会议的成员队列
    meetingQueue() {
      return this.$store.state.multimedia_meetingmode.meetingQueue;
    },
    // 获取加入会议的流队列
    meetingRemoteStreams() {
      return this.$store.state.multimedia_meetingmode.meetingRemoteStreams;
    },
    // 当前在主屏展示的人员ID，如果是null的话，就是展示的当前用户自己
    membersOnTheHomeScreen() {
      return this.$store.state.multimedia_meetingmode.membersOnTheHomeScreen;
    },
    // 根据主屏展示对象的ID获取用户基本信息
    homeScreenUserInfo() {
      let homeScreenUserInfo = new Object();
      // 如果当前主屏展示的是用户自己的话
      if (this.membersOnTheHomeScreen === null) {
        homeScreenUserInfo.profilePhoto = this.$store.getters.userInfo.avatar;
        homeScreenUserInfo.name = this.$store.getters.userInfo.name;
        homeScreenUserInfo.uid = this.$store.getters.userInfo.id;
        homeScreenUserInfo.volumeLevel =
          this.$store.state.multimedia_meetingmode.meetingVolume || 0;
        homeScreenUserInfo.vipStatus = this.$store.getters.userInfo.vipStatus;
      } else {
        // 在参加成员队列中找到对应的成员信息
        let needShowMember = this.meetingQueue.filter(
          (item) => item.uid === this.membersOnTheHomeScreen
        );
        homeScreenUserInfo.profilePhoto = needShowMember[0].profilePhoto;
        homeScreenUserInfo.name = needShowMember[0].name;
        homeScreenUserInfo.uid = needShowMember[0].uid;
        homeScreenUserInfo.volumeLevel = needShowMember[0].volumeLevel || 0;
        homeScreenUserInfo.vipStatus = needShowMember[0].vipStatus || 0;
      }
      return homeScreenUserInfo;
    },
    // 播放窗口是否是普通大小状态
    meetingWindowIsNormal() {
      return this.$store.state.multimedia_meetingmode.meetingWindowStyle ===
        "largestStyle"
        ? false
        : true;
    },
  },
  methods: {
    nameSfx(fileName) {
      if (!fileName) return "";
      const arr = fileName.split(".");
      let str = arr[arr.length - 1].toUpperCase();
      if (str.length > 3) {
        str = str.substring(0, 3);
      }
      return str;
    },
    // 初始化主屏幕播放窗口
    initHomeScreenPlayer() {
      // 初始化本地视频播放,将主屏幕对象储存起来
      this.$store.commit("setHomeScreenElement", this.$refs.homeScreenElement);
      this.$nextTick(() => {
        initLocalClient(this.homeScreenElement)
          .then((e) => {
            console.log(e);
          })
          .catch((e) => {
            if (e === "need to hang up") {
              //网易云信初始化失败，在这里执行挂断操作
              // console.log("need to hang up");
            }
          });
      });
    },
    // 播放其他远端成员的视频
    playOtherMemberVideo(showMemberId) {
      if (
        this.meetingRemoteStreams.length !== 0 &&
        this.meetingRemoteStreams.filter(
          (item) => item.getId() === showMemberId
        ).length !== 0
      ) {
        // 在流队列中找到对应的成员流对象
        let needShowMember = this.meetingRemoteStreams.filter(
          (item) => item.getId() === showMemberId
        )[0];
        // 开始播放对端的流
        playOtherMemberVideo(needShowMember, this.homeScreenElement)
          .then((result) => {
            console.log(result);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // 删除加入提示
    deleteJoinTips(uid) {
      let joinMeetingMemberTips = this.joinMeetingMemberTips.filter(
        (item) => item.uid !== uid
      );
      this.$store.commit("setJoinMeetingMemberTips", joinMeetingMemberTips);
      this.$forceUpdate();
    },
    // 将会议窗口缩为最小，并悬浮在浏览器右上角
    hanging() {
      this.$store.commit(
        "setMeetingWindowStyle",
        this.$store.state.multimedia_meetingmode.meetingWindowStyle !==
          "smallStyle"
          ? "smallStyle"
          : "normalStyle"
      );
    },
    // 改变会议窗口大小
    changeSize() {
      this.meetingWindowIsNormal &&
        this.$store.commit("setIMWindowSize", "mini");
      this.$nextTick(() => {
        document.querySelector(".imelink-container--miniscreen").style.zIndex =
          "2088";
      });
      this.$store.commit(
        "setMeetingWindowStyle",
        this.meetingWindowIsNormal ? "largestStyle" : "normalStyle"
      );
      // this.$store.commit("setMeetingWindowStyle", "largestStyle");
    },
    //刷新操作
    refresh() {
      let newAccout = this.$store.state.multimedia_meetingmode.newAccout;
      this.$nextTick(() => {
        // 如果当前是开启了会议的，则获取会议的分享地址
        if (this.$store.state.multimedia_meetingmode.meetingWindowStatus) {
          // 先获取会议地址，再挂断
          this.shareMeetingLink().then((meetingAddress) => {
            this.hangupTheCall().then((result) => {
              // 将会议页面刷新设置为false
              if (newAccout) {
                this.$store.commit("setNewAccout", newAccout);
                this.$store.commit("setMeetingType", "createNewUser");
              }
              let meetingRoomId = meetingAddress.match(/meeting\/(\S{16})/)[1];
              // localStorage.setItem("meetingReloadFlag", "refresh");
              localStorage.setItem("meetingRoomId", meetingRoomId);
              this.$router.replace({
                path: `/meeting/${meetingRoomId}`,
              });
            });
          });
        } else {
          // 如果是旧版的即时通讯窗口开启，则执行旧版挂断操作
          forcedHangUp()
            .then(async (result) => {
              window.location.reload();
            })
            .catch((err) => {});
        }
      });
    },
    // 挂断会议
    async hangupTheCall() {
      return new Promise(async (resolve, reject) => {
        // 先通知后台再挂断网易云信
        let params = {
          chatType: "groupChat",
          subjectId:
            this.$store.state.multimedia_meetingmode.substituteUserId ||
            this.$store.state.multimedia_meetingmode.meetingGroupId,
        };
        let result = await RESTApi.exitMeeting(params);
        if (result.code == 200) {
          hangUpTheCall()
            .then((e) => {
              // 处理完数据后，将窗口关闭
              this.$store.commit("setMeetingWindowStatus", false);
              resolve("success");
            })
            .catch((e) => {
              reject(e);
            });
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
          // reject(result.message);
        }
      });
    },
    // 获取分享会议链接地址
    shareMeetingLink() {
      return new Promise(async (resolve, reject) => {
        let params = {
          groupId: this.$store.state.multimedia_meetingmode.meetingGroupId,
          meetingId: this.$store.state.multimedia_meetingmode
            .meetingChannelName,
        };
        let result = await RESTApi.getShareAddress(params);
        if (result.code == 200) {
          resolve(result.data.data.meetingAddress);
        } else {
          reject(result.message);
        }
      });
    },
    _parseTime(str) {
      if (!str) return;
      return parseTime(str, "{h}:{i}:{s}");
    },
    _parseTime2(str) {
      if (!str) return;
      return parseTime(str, "{y}-{m}-{d}");
    },
    scrollTo(val = "0px") {
      this.$refs.meeting_chat.scrollTop = val;
    },
    async scrollToBottom() {
      await this.$nextTick();
      if (this.$refs.meeting_chat) {
        this.scrollTo(this.$refs.meeting_chat.scrollHeight);
      }
    },
    // 下载当前文件
    handleContentClick(message) {
      const url = `${env(
        "BASEURL"
      )}/rest/user/download?downloadUrl=${this.formartImageUrl(
        message.onlineurl || message.url
      )}&fileName=${encodeURIComponent(
        message.fileName
          .replace('<font color="red">', "")
          .replace("</font>", "")
      )}`;
      downloadFile(
        url,
        message.fileName
          .replace('<font color="red">', "")
          .replace("</font>", "")
      );
    },
    // 播放录音
    play() {
      this.$message({
        message: this.$t("notBeViewedInTheMeeting"),
        type: "warning",
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.joinTheMeetingItem-enter-active {
  transition: height 1s, opacity 2s, marginBottom 1s, transform 0.3s;
}

.joinTheMeetingItem-leave-active {
  transition: height 0.5s, opacity 0.3s, marginBottom 1s;
}

.joinTheMeetingItem-enter {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}

.joinTheMeetingItem-leave-to {
  opacity: 0;
  height: 0px;
  margin-bottom: 0px;
}

.displayPanel {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 1px;
  .showSharingMask {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    background: rgba(0,0,0,0.7);
  }
  .commontiming {
    z-index: 3;
    position: absolute;
  }

  .roomIdStyle {
    display: flex;
    align-items: center;
    color: #fff;
    width: fit-content;
    top: 12px;
    left: 50%;
    font-size: 12px !important;
    transform: translateX(-50%);
    height: 28px;
    padding: 0 10px;
    background: #000;
    border-radius: 12px;

    .isInMeeting_box {
      margin-right: 12px;
    }
  }

  @media screen and (max-width: 700px) {
    .roomIdStyle {
      top: 50px !important;
      left: 20px !important;
      transform: translateX(0%);
    }
  }

  .smallScreen {
    top: 50px !important;
    left: 20px !important;
    transform: translateX(0%);
  }

  .timingTextBox {
    height: 28px;
    padding: 0 10px;
    background: #000000;
    border-radius: 12px;
    top: 12px;
    left: 20px;
    // right 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #FFFFFF;

    .liveStatus {
      width: 10px;
      height: 10px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      margin-right: 6px;
      position: relative;

      .inner {
        display: flex;
        width: 4px;
        height: 4px;
        background: #ff4646;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .switchSizeBox {
    height: 28px;
    padding: 0 10px;
    background: #000000;
    border-radius: 12px;
    top: 12px;
    right: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #FFFFFF;

    .line {
      width: 1px;
      height: 12px;
      background: rgba(255, 255, 255, 0.4);
      margin: 0 13px;
      // border: 1px solid ;
    }

    i {
      cursor: pointer;
      font-size: 16px;
    }
  }

  .refresh {
    height: 28px;
    width: 28px;
    // padding: 0 10px;
    background: #000000;
    border-radius: 12px;
    top: 12px;
    // right: 12px;
    left: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #FFFFFF;
    cursor: pointer;
  }

  .timingForSharingScreen {
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    height: 36px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #FFFFFF;
    background: #363636;
    border-radius: 0 0 16px 16px;

    .sharingScreenStatus {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #33CC66;
      margin-right: 10px;
    }
  }

  .joinTheMeetingTipsBox {
    z-index: 10;
    position: absolute;
    overflow: hidden;
    bottom: 150px;
    right: 20px;
  }

  .videoDisplayBox {
    position: relative;
    z-index: 1;
  }

  .commonDisplayStyle {
    width: 100%;
    height: 100%;
    padding-top: 1px;
  }

  .memberDisplayBox {
    .memberInfoBox {
      width: 100%;
      display: flex;
      align-items: center;
      flex-flow: column;
      justify-content: flex-start;

      .memberImageBox {
        position: relative;

        .imageBox {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          border-radius: 50%;
          border: 2px solid rgba(0, 0, 0, 0.1);
          overflow: hidden;
        }

        .isInitiator {
          width: 16px;
          height: 16px;
          position: absolute;
          bottom: 6px;
          right: 4px;
          z-index: 2;
          background: url('../../../../assets/images/newMeetingMultiplayer/initiatorIcon_large.png') no-repeat;
          background-size: 100% 100%;
        }


        .isInitiator_largest {
          width: 25px;
          height: 25px;
          position: absolute;
          bottom: 6px;
          right: 4px;
          z-index: 2;
          background: url('../../../../assets/images/newMeetingMultiplayer/initiatorIcon_largest.png') no-repeat;
          background-size: 100% 100%;
        }

        .vipIcon{
          position: absolute;
          top: 6px;
          right: 3px;
          z-index: 2;
          svg{
            width: 16px;
            height: 16px;
          }
        }

        .vipIcon_largest{
          position: absolute;
          top: 6px;
          right: 4px;
          z-index: 2;
          svg{
            width: 25px;
            height: 25px;
          }
        }
      }

      .memberNameBox {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        .memberName {
          display: inline-block;
          padding: 0 8px;
          height: 25px;
          border-radius: 10px;
          line-height: 25px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
          color: #FFFFFF;
          background: rgba(255, 255, 255, 0.22);
        }
        .xvipName{
          color:#ff7e4e
        }
      }
    }
  }

  // 会议里的聊天列表
  .meeting_chat {
    z-index: 100;
    color: #fff;
    width: 29%;
    min-width: 262px;
    top: 50px;
    bottom: 150px;
    right: 0;
    padding: 0 18px;
    position: absolute;
    // background: gray;
    text-align: right;
    font-size: 14px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-light();

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    .d-time {
      text-align: center;
      margin: 10px 0;
    }

    .chat-sender {
      font-size:12px;
      padding: 10px 0;
    }

    .chat-text {
      font-size:14px;
      display: flex;
      justify-content: flex-end;
    }

    .chat_style {
      position: relative;
      background: rgba(0, 0, 0, 0.5);
      color: #000;
      width: fit-content;
      padding: 8px 12px;
      border-radius: 6px;
      font-size: 12px;
      color: #fff;
      margin-bottom: 20px;

      &:after {
        position: absolute;
        content: '';
        width: 18px;
        height: 16px;
        right: -18px;
        top: 4px;
        background: url('../../../../assets/images/newMeetingMultiplayer/charArr.png');
      }
    }

    .link_style{
      position: relative;
      background: rgba(0, 0, 0, 0.5);
      background: #f1f1f1;
      color: #000;
      width: fit-content;
      max-width:100%;
      // padding: 8px 12px;
      border-radius: 6px;
      font-size: 14px;
      margin-bottom: 20px;
      text-align:left;
      word-break: break-all
      .pd{
        padding: 8px 12px;
      }
      .translateMsg{
         border-top:0.05px solid #fff;
      }
      &:after {
        // position: absolute;
        // content: '';
        // width: 18px;
        // height: 16px;
        // right: -18px;
        // top: 4px;
        // background: url('../../../../assets/images/newMeetingMultiplayer/charArr.png');
        content: '';
        position: absolute;
        right: -13px;
        top: 4px;
        width: 0;
        height: 0;
        font-size: 0;
        border: solid 8px;
        border-color: transparent;
        border-left-color: #f1f1f1;
      }
    }

    .image-style {
      overflow: hidden;
      display: flex;
      justify-content: flex-end;

      .me-thumb {
        height: 100px;
        min-width: 100px;
        overflow: hidden;
        border-radius: 6px;
        background: rgba(0, 0, 0, 0.5);
        img,.el-image {
          height: 100%;
        }
      }
    }

    // 文件
    .file--box {
      background: #e4f0fc !important;
      padding: 10px 15px;
      border-radius: 6px;
      position: relative;
      cursor: pointer;

      .imelink-message-file__content {
        // background: #c6edcc;
        color: #000;

        p {
          line-height: 18px;
          display: block;
          margin: 0;
        }

        .imelink-message-file__name {
          display: inline-block;
          max-width: 160px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .fileIcon {
        width: 50px;
        height: 54px;
      }
    }

    // 语音
    .chat-voice {
      font-size: 12px;
      cursor: pointer;

      .imelink-message-voice__icon {
        left: 1.5rem;
      }

      .imelink-message-voice__duration {
        color: #fff;
      }
    }
  }
}

.mb20 {
  margin-bottom: 20px;
}

.flex {
  display: flex;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.j-c {
  justify-content: center;
}

.a-c {
  align-items: center;
}

#samllVoide {
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: 20%;
  left: 9px;
  z-index: 1000;
  border-radius: 60px;
  &.smallScreen {
    left:0px!important;
    bottom:18%!important;
    top:auto!important;
  }

  .largestStyle {
  }
}
</style>
<style>
.meeting_chat .el-image__inner,
.el-image__placeholder,
.el-image__error {
  width: auto !important;
}
</style>
