var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "memberItem",
      class: _vm.itemStyle,
      style: {
        border:
          _vm.membersOnTheHomeScreen === null ? "2px solid #52BD68" : "none"
      },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.enlargeTheVideo.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        {
          ref: "localVideo",
          staticClass: "memberShow",
          attrs: { id: "localVideo", "data-uid": _vm.localInfo.uid }
        },
        [
          !_vm.needShowVideo
            ? _c("el-image", {
                staticClass: "memberImage",
                attrs: {
                  src: _vm.formartImageUrl(_vm.localInfo.profilePhoto),
                  fit: "cover"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "initiatorIcon" },
        [
          _vm.localInfo.uid === _vm.meetingCreatorId
            ? _c("el-image", {
                staticStyle: {
                  width: "100%",
                  height: "100%",
                  display: "block"
                },
                attrs: {
                  src: require("../../../../assets/images/newMeetingMultiplayer/initiatorIcon.png"),
                  fit: "fill"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.localInfo.vipStatus
        ? _c(
            "div",
            { staticClass: "vipIcon" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: _vm.$t("paidMember"),
                    placement: "bottom",
                    "popper-class": "me-tooltip-effect"
                  }
                },
                [
                  _c("VipIcon", {
                    attrs: {
                      vipStatus: _vm.localInfo.vipStatus,
                      iconWidth: 14,
                      iconHeight: 14,
                      id: _vm.localInfo.uid
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "memberInfo" }, [
        _c(
          "div",
          { staticClass: "microphoneBox" },
          [
            _vm.memberMicrophoneStatus
              ? _c("MonitorSelfAudioStatus", {
                  attrs: {
                    volumeLevel:
                      _vm.$store.state.multimedia_meetingmode.meetingVolume
                  }
                })
              : _c("img", {
                  staticClass: "microphoneCloseStyle",
                  attrs: {
                    src: require("../../../../assets/images/newMeetingMultiplayer/microphoneCloseIcon.png")
                  }
                })
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "memberName",
            class: _vm.localInfo.vipStatus ? "xvipName" : "",
            attrs: { title: _vm.localInfo.name }
          },
          [_vm._v("\n      " + _vm._s(_vm.localInfo.name) + "\n    ")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }