var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "displayPanel" },
    [
      _c("div", { staticClass: "commontiming timingTextBox" }, [
        _vm._m(0),
        _c("span", { staticClass: "sharingScreenStatus" }),
        _vm._v(_vm._s(_vm.meetingTimingText) + "\n  ")
      ]),
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: _vm.$t("refreshMeeting"),
            placement: "bottom"
          }
        },
        [
          _c(
            "div",
            { staticClass: "commontiming refresh", on: { click: _vm.refresh } },
            [_c("i", { staticClass: "el-icon-refresh-right" })]
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "commontiming switchSizeBox" },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: _vm.$t("minimize"),
                placement: "bottom"
              }
            },
            [
              _c(
                "i",
                {
                  staticClass: "noDrag",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.hanging.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("SizeButton", {
                    attrs: { buttonType: "minimize", color: "white" }
                  })
                ],
                1
              )
            ]
          ),
          _c("span", { staticClass: "line" }),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content:
                  _vm.$store.state.multimedia_meetingmode.meetingWindowStyle ==
                  "largestStyle"
                    ? _vm.$t("reduction")
                    : _vm.$t("maximize"),
                placement: "bottom"
              }
            },
            [
              _c(
                "i",
                { staticClass: "noDrag", on: { click: _vm.changeSize } },
                [
                  _c("SizeButton", {
                    attrs: {
                      buttonType:
                        _vm.$store.state.multimedia_meetingmode
                          .meetingWindowStyle == "largestStyle"
                          ? "full"
                          : "normalcy",
                      color: "white"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "commontiming roomIdStyle",
          class:
            _vm.$store.state.multimedia_meetingmode.meetingWindowStyle ===
            "largestStyle"
              ? "largestStyle"
              : _vm.screenSizeForMeeting
        },
        [
          _c("div", { staticClass: "isInMeeting_box" }),
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.$store.state.multimedia_meetingmode.meetingRoomId ||
                  _vm.meetingRoomId
              ) +
              "\n  "
          )
        ]
      ),
      false
        ? _c(
            "div",
            { ref: "meeting_chat", staticClass: "meeting_chat" },
            _vm._l(_vm.$store.state.multimedia_meetingmode.messageAll, function(
              message,
              index
            ) {
              return _c(
                "div",
                {
                  key: message.id + index,
                  ref: message.sendTime + index,
                  refInFor: true,
                  class: message.id + index
                },
                [
                  message.type == "text"
                    ? _c("div", [
                        _c("div", { staticClass: "chat-sender" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(message.fromUser.displayName) +
                              "\n          " +
                              _vm._s(_vm._parseTime(message.sendTime)) +
                              "\n        "
                          )
                        ]),
                        _c("div", { staticClass: "chat-text" }, [
                          _c(
                            "div",
                            { staticClass: "link_style" },
                            [
                              message.text
                                ? _c("div", {
                                    staticClass: "pd",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm
                                          .$emoReplaceToImage(message.text)
                                          .replace(/\r\n|\n/gi, "<br/>")
                                      )
                                    }
                                  })
                                : _c("div", {
                                    staticClass: "pd",
                                    domProps: {
                                      innerHTML: _vm._s(message.text)
                                    }
                                  }),
                              message.msgs
                                ? _vm._l(message.msgs.msgs, function(item) {
                                    return _c("div", { key: item.id }, [
                                      message.msgs.sourceLanguage !=
                                        item.language &&
                                      item.language == _vm.imSelectCode
                                        ? _c(
                                            "p",
                                            { staticClass: "translateMsg pd" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(item.translateMsg) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  })
                                : _vm._e()
                            ],
                            2
                          )
                        ])
                      ])
                    : _vm._e(),
                  message.type == "image"
                    ? _c("div", [
                        _c("div", { staticClass: "chat-sender" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(message.fromUser.displayName) +
                              "\n          " +
                              _vm._s(_vm._parseTime(message.sendTime)) +
                              "\n        "
                          )
                        ]),
                        _c("div", { staticClass: "image-style mb20" }, [
                          _c(
                            "div",
                            { staticClass: "me-thumb" },
                            [
                              _c("el-image", {
                                staticStyle: { width: "auto" },
                                attrs: {
                                  src: message.url,
                                  "preview-src-list": [message.url]
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e(),
                  message.type == "video" && message.url
                    ? _c("div", [
                        _c("div", { staticClass: "chat-sender" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(message.fromUser.displayName) +
                              "\n          " +
                              _vm._s(_vm._parseTime(message.sendTime)) +
                              "\n        "
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "mb20 flex flex-end",
                            staticStyle: { "margin-left": "auto" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.play(message)
                              }
                            }
                          },
                          [
                            _c("el-video", {
                              staticStyle: {
                                height: "100%",
                                "border-radius": "6px"
                              },
                              attrs: {
                                src: message.url,
                                "alert-title": message.fizeName,
                                noPlayVideo: true
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  message.type == "voice"
                    ? _c("div", [
                        _c("div", { staticClass: "chat-sender" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(message.fromUser.displayName) +
                              "\n          " +
                              _vm._s(_vm._parseTime(message.sendTime)) +
                              "\n        "
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "imelink-message-voice chat-voice flex flex-end",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.play(message)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "chat_style flex j-c a-c" },
                              [
                                _c("el-progress", {
                                  staticClass:
                                    "imelink-message-voice__progress",
                                  attrs: {
                                    type: "circle",
                                    percentage:
                                      message.percentage > 100
                                        ? 100
                                        : message.percentage,
                                    color: "#33cc66",
                                    "stroke-width": 2,
                                    width: 30,
                                    "show-text": false
                                  }
                                }),
                                _c("i", {
                                  staticClass:
                                    "imelink-message-voice__icon el-icon--play"
                                }),
                                _c("audio", {
                                  ref: "voice",
                                  refInFor: true,
                                  attrs: { preload: "auto", src: message.url }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "imelink-message-voice__duration"
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(message.duration) +
                                        "''\n            "
                                    )
                                  ]
                                ),
                                message.msgs
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "imelink-message-voice__speechConvertMessage"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              message.msgs.speechConvertMessage
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  message.type == "file"
                    ? _c("div", [
                        _c("div", { staticClass: "chat-sender" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(message.fromUser.displayName) +
                              "\n          " +
                              _vm._s(_vm._parseTime(message.sendTime)) +
                              "\n        "
                          )
                        ]),
                        _c("div", { staticClass: "flex flex-end" }, [
                          _vm
                            .formartNewFileIcon(_vm.nameSfx(message.fileName))
                            .indexOf("file.") !== 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "file--box flex mb20",
                                  on: {
                                    click: function($event) {
                                      return _vm.handleContentClick(message)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "imelink-message-file__icon"
                                    },
                                    [
                                      _c("el-image", {
                                        staticClass: "fileIcon",
                                        attrs: {
                                          src: _vm.formartNewFileIcon(
                                            _vm.nameSfx(message.fileName)
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "imelink-message-file__content"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "imelink-message-file__name"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(message.fileName) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "imelink-message-file__foot imelink__foot"
                                        },
                                        [
                                          _c("small", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.toBytesSize(
                                                  message.fileSize
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _c(
                                "div",
                                { staticClass: "file--box flex mb20" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "imelink-message-file__icon"
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-folder"
                                      }),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm.nameSfx(message.fileName))
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "imelink-message-file__content"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "imelink-message-file__name"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(message.fileName) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "imelink-message-file__foot imelink__foot"
                                        },
                                        [
                                          _c("small", [
                                            _vm._v(
                                              _vm._s(
                                                isNaN(
                                                  _vm.toBytesSize(
                                                    message.fileSize
                                                  )
                                                )
                                                  ? "0 KB"
                                                  : _vm.toBytesSize(
                                                      message.fileSize
                                                    )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                        ])
                      ])
                    : _vm._e()
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.needVideoShow,
            expression: "needVideoShow"
          }
        ],
        ref: "homeScreenElement",
        staticClass: "commonDisplayStyle videoDisplayBox",
        attrs: { id: "videoDisplayBox" }
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.needVideoShow,
              expression: "!needVideoShow"
            }
          ],
          staticClass: "commonDisplayStyle memberDisplayBox"
        },
        [
          _c(
            "div",
            {
              staticClass: "memberInfoBox",
              style: {
                marginTop: `${_vm.memeberMarginTop}px`
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "memberImageBox",
                  style: {
                    width: `${_vm.memeberImageSize}px`,
                    height: `${_vm.memeberImageSize}px`
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "imageBox" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: _vm.formartImageUrl(
                            _vm.homeScreenUserInfo.profilePhoto
                          ),
                          fit: "cover"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.homeScreenUserInfo.uid === _vm.meetingCreatorId
                    ? _c("div", {
                        class:
                          _vm.meetingWindowStyle == "largestStyle"
                            ? "isInitiator_largest"
                            : "isInitiator"
                      })
                    : _vm._e()
                ]
              ),
              _c("div", { staticClass: "memberNameBox" }, [
                _c(
                  "div",
                  {
                    staticClass: "memberName",
                    class: _vm.homeScreenUserInfo.vipStatus ? "xvipName" : "",
                    staticStyle: { display: "flex", "align-items": "center" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.homeScreenUserInfo.name) +
                        "\n          "
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("paidMember"),
                          placement: "bottom",
                          "popper-class": "me-tooltip-effect"
                        }
                      },
                      [
                        _c("VipIcon", {
                          staticClass: "icon",
                          staticStyle: { "margin-left": "1px" },
                          attrs: {
                            vipStatus: _vm.homeScreenUserInfo.vipStatus,
                            iconWidth: 14,
                            iconHeight: 14,
                            id: _vm.homeScreenUserInfo.uid
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ]
      ),
      _vm.joinMeetingMemberTips.length !== 0
        ? _c(
            "div",
            { staticClass: "joinTheMeetingTipsBox" },
            [
              _c(
                "transition-group",
                { attrs: { name: "joinTheMeetingItem", tag: "div" } },
                _vm._l(_vm.joinMeetingMemberTips, function(item, inx) {
                  return _c("JoinTheMeetingTips", {
                    key: item.name + inx,
                    attrs: { joinMeetingMemberInfo: item },
                    on: { deleteJoinTips: _vm.deleteJoinTips }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("OperatingBox", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.sdkReady,
            expression: "sdkReady"
          }
        ],
        attrs: { screenSizeForMeeting: _vm.screenSizeForMeeting }
      }),
      _vm.showSharingMask
        ? _c("div", { staticClass: "showSharingMask" })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "liveStatus" }, [
      _c("span", { staticClass: "inner" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }