
import NERTC from "nertc-web-sdk/NERTC"
import config from "../../webRTCSdk/config.js";
import { getToken } from "../../webRTCSdk/getToken.js";
import store from "../../../../store/index";
import { getPersonnelDetails } from "../../../../api/newVersion/personalCenter";
import {
  meetingSubscribe,
  getMeetingDevice,
  judgeHardwareOccupy,
  stopMeetingVideo,
  stopMeetingAudio,
} from "./local"
// 初始化
export const initializeSDK = () => {
  return new Promise((resolve, reject) => {
    if (store.state.multimedia_meetingmode.meetingChannelName != null) {
      initGetToken().then(token => {
        store.commit("setMeetingToken", token);
        initializeDefaultListening().then(e => {
          resolve(e)
        }).catch(e => {
          reject(e)
        });
      }).catch(e => {
        reject(e)
      })
    } else {
      reject(new Error("房间号为空，请检查初始化流程"))
    }
  })
}

// 初始化远程客户端，添加默认监听
const initializeDefaultListening = () => {
  return new Promise(async (resolve, reject) => {
    if (store.state.multimedia_meetingmode.meetingClient === null) {
      NERTC.Logger.enableLogUpload();
      let client = NERTC.createClient({
        appkey: config.appkey,
        debug: true,
      });
      // 监听检测用户下行的网络质量
      client.on('network-quality', async _data => {
        console.log("network-quality:", JSON.stringify(_data));
      })
      //监听成员加入房间,只是进入房间，还没发布流
      client.on('peer-online', async (evt) => {
        // 获取当前以有的队列，深拷贝
        let meetingQueue = _.cloneDeep(store.state.multimedia_meetingmode.meetingQueue).slice(0);
        // 先判断队列中是否有这个人，有的话，就不再添加了

        if (meetingQueue.some((item) => item.uid === evt.uid)) {
          return;
        }
        let memberInfo = {
          video: false,
          audio: false,
          volumeLevel: 0,
          name: evt.uid,
          profilePhoto: "noPhoto",
          uid: evt.uid,
        };
        // 这里有可能出现一种情况，就是stream-added的流已经监听到了，但是peer-online里面请求用户详情的getPersonnelDetails方法还没返回数据，所以这里先生成一个用户信息，装到队列中去
        meetingQueue.unshift(memberInfo);
        store.commit("setMeetingQueue", meetingQueue);
        // 根据进来的用户ID，获取他的详细信息
        let result = await getPersonnelDetails(evt.uid);
        // 默认视频和音频是关闭的，音量是0,成员的音视频对象（用于播放视频音频）
        if (result.code == 200) {
          // 存入数据后，再重新拷贝一次
          meetingQueue = store.state.multimedia_meetingmode.meetingQueue.slice(0);
          meetingQueue = meetingQueue.map(item => {
            if (item.uid === result.data.data.providerId) {
              item.name = result.data.data.name;
              item.profilePhoto = result.data.data.profilePhoto;
              item.uid = result.data.data.providerId;
              item.vipStatus = result.data.data.vipStatus
            }
            return item;
          })


          store.commit("setMeetingQueue", meetingQueue);
          // 获取当前展示加入提示的用户的队列，将新加的成员放到第一个
          let joinMeetingMemberTips = store.state.multimedia_meetingmode.joinMeetingMemberTips.slice(0);
          joinMeetingMemberTips.unshift({ name: result.data.data.name });
          store.commit("setJoinMeetingMemberTips", joinMeetingMemberTips);
        }
      });
      // 监听浏览器屏幕共享关闭事件
      client.on('stopScreenSharing', () => {
        store.commit("setIsSharingScreen", false)
      });
      //监听成员离开房间
      client.on('peer-leave', async (evt) => {
        console.warn(`${evt.uid} 离开房间`);
        // 如果离开的这个人是正在主屏上播放的人，则将主屏设置为本地用户自己
        if (evt.uid === store.state.multimedia_meetingmode.membersOnTheHomeScreen) {
          store.commit("setMembersOnTheHomeScreen", null);
        }
        // 清理离开房间的成员流信息
        let meetingQueue = store.state.multimedia_meetingmode.meetingQueue.filter(
          (item) => item.uid !== evt.uid
        );
        store.commit("setMeetingQueue", meetingQueue);

      });
      // 监听房间当前音量最大的人的音量，并改变队列中的值
      client.on('active-speaker', _data => {
        changeUiByVolume(_data.uid, _data.level)
      })
      // 监听房间有成员新发布的流
      client.on('stream-added', (evt) => {
        let remoteStream = evt.stream;
        console.warn('收到新加入的流: ',remoteStream, remoteStream.getId());
        // 将成员的流存储起来
        refreshRemoteStreamsList(remoteStream).then(e => {
          // 根据流的一些状态更改UI队列的状态
          changeUiByState(remoteStream).then(reslut => {
            /*获取当前聊天室中的聊天列表的人员信息（UI队列）*/
            let meetingQueue = store.state.multimedia_meetingmode.meetingQueue;
            // 判断UI队列中是否有这个人的流
            let needSubscribe = meetingQueue.some((item) => item.uid === remoteStream.getId());
            // 验证已有队列中是否有这个成员的信息，如果没有就不订阅
            if (needSubscribe) {
              // 这里只是加入了流，还需要通过 meetingSubscribe 方法对其发起视频流的进行订阅，来将对方的视频流渲染到视频画布上。
              meetingSubscribe(remoteStream)
            }
          })
        })
      })
      // 监听房间成员停止发布流
      client.on('stream-removed', (evt) => {
        let remoteStream = evt.stream;
        console.warn('对方停止发布流: ', remoteStream.getId());
        refreshRemoteStreamsList(remoteStream).then(e => {
          changeUiByState(remoteStream).then(reslut => {
            // 关闭摄像头后，导致听不到声音，这里暂时处理，可能会导致其他问题
            // remoteStream.stop()   
            // 如果当前退出对象正在大屏展示视频
            if (remoteStream.getId() === store.state.multimedia_meetingmode.membersOnTheHomeScreen) {
              if (!remoteStream.video && !remoteStream.screen) {
                store.commit("setMembersOnTheHomeScreen", null);
              }
            }
          });
        });
      });
      // 监听设备移除的事件
      client.on('deviceRemove', () => {
        // 获取本地设备列表
        getMeetingDevice().then(e => {
          // 如果本地没有硬件队列了，咋关闭对应的音视频流
          if (!store.state.multimedia_meetingmode.localCameraStatus) {
            stopMeetingVideo().then(e => { console.log(e) }).catch(e => { console.log(e) })
          } else {
            // 如果还有队列，则判断硬件是否可用顺便更新一下原生多媒体队列
            judgeHardwareOccupy('video').then(() => { }).catch(err => {
              stopMeetingVideo().then(e => { console.log(e) }).catch(e => { console.log(e) })
            })
          }
          if (!store.state.multimedia_meetingmode.localMicrophoneStatus) {
            stopMeetingAudio().then(e => { console.log(e) }).catch(e => { console.log(e) })
          } else {
            // 如果还有队列，则判断硬件是否可用顺便更新一下原生多媒体队列
            judgeHardwareOccupy('audio').then(() => { }).catch(err => {
              stopMeetingAudio().then(e => { console.log(e) }).catch(e => { console.log(e) })
            })
          }
        }).catch(err => {
          console.log(err)
        })
      })
      // 监听流
      client.on('stream-subscribed', (evt) => {
        let remoteStream = evt.stream;
        console.warn('对方开始发布流: ', remoteStream.getId());

        refreshRemoteStreamsList(remoteStream).then(e => {
          changeUiByState(remoteStream).then(reslut => {
            // 判断当前大屏是否就在播放这个
            let showScreen = null;
            let el = store.state.multimedia_meetingmode.meetingMemberDoms;
            if (remoteStream.getId() === store.state.multimedia_meetingmode.membersOnTheHomeScreen && store.state.multimedia_meetingmode.homeScreenElement !== null) {
              showScreen = store.state.multimedia_meetingmode.homeScreenElement;
            } else {
              //用于播放对方视频画面的div节点
              showScreen = el.find(
                (item) => item.dataset.uid === remoteStream.getId()
              );
            }
            if (showScreen) {
              playOtherMemberVideo(remoteStream, showScreen).then(result => {
                console.log(result)
              }).catch(err => {
                console.log(err)
              });
            }
          })
        })
      })
      store.commit("setMeetingClient", client);
      resolve("远程客户端初始化成功");
    } else {
      resolve("远程客户端已启动,无需再次初始化");
    }
  })
}

// 更新会议室成员dom元素队列
export const initialMemberMonitor = (el) => {
  return new Promise((resolve, reject) => {
    store.commit("setMeetingMemberDoms", el);
    resolve("success")
  })
}

// 处理收到的流更新流队列
const refreshRemoteStreamsList = (remoteStream) => {
  return new Promise((resolve, reject) => {
    // 获取这次会议的流队列
    let meetingRemoteStreams = store.state.multimedia_meetingmode.meetingRemoteStreams.slice(0);
    // 判断流队列中是否有这个流对象
    if (meetingRemoteStreams.some((item) => item.getId() === remoteStream.getId())) {
      // 如果有的话，就把这个已有的流对象替换成新的流对象
      meetingRemoteStreams.forEach((item, index) => {
        // 找到流队列中对应的这个流对象
        if (item.getId() === remoteStream.getId()) {
          // 把当前对象替换成最新的
          meetingRemoteStreams.splice(index, 1, remoteStream);
        }
      })
    } else {
      // 如果没有的话，就将流存入流队列中
      meetingRemoteStreams.push(remoteStream);
    }
    // 将最新的流队列存贮起来
    store.commit("setMeetingRemoteStreams", meetingRemoteStreams);
    resolve("success")
  })
}

// 开始播放其他成员的视频
export const playOtherMemberVideo = (remoteStream, div) => {
  return new Promise((resolve, reject) => {
    let showScreen = div;
    // 如果当前流是在主屏上展示的，则将播放对象设置为主屏对象
    if (remoteStream.getId() === store.state.multimedia_meetingmode.membersOnTheHomeScreen && store.state.multimedia_meetingmode.homeScreenElement !== null) {
      showScreen = store.state.multimedia_meetingmode.homeScreenElement;
    }
    // 播放远端视频以前，得先stop一下
    remoteStream.stop();


    // hack 方法， 后期需要更改
    setTimeout(() => {
      let chidren = document.getElementById('videoDisplayBox').childNodes
      if (chidren.length > 1) {
        chidren[0].style.display = "none"
      }
    }, 100);



    remoteStream.play(showScreen, { screen: remoteStream.screen, video: !remoteStream.screen }).then(async (e) => {
      resolve("play success vvvvvv")
    }).catch((err) => {
      reject('播放其他成员视频失败: ' + err)
    });

    if (document.getElementById('xxyy') && remoteStream.screen && remoteStream.video) {
      remoteStream.play(document.getElementById('xxyy'), { video: remoteStream.video, screen: false }).then(async (e) => {
        resolve("play success  yyyyyyy")
      }).catch((err) => {
        reject('播放其他成员视频失败: ' + err)
      });
    }
  })
}

// 根据对方取消发布订阅状态改变其在队列中的数据
export const changeUiByState = (remoteStream) => {
  return new Promise((resolve, reject) => {
    // 如果是多人通讯的话，改变对方数据中的一些状态
    // 获取当前聊天室中的聊天列表的人员信息*
    let meetingQueue = store.state.multimedia_meetingmode.meetingQueue.slice(0);
    let displayName = "";
    meetingQueue = meetingQueue.map(item => {
      // 将成员队列中的成员信息修改为最新的流的状态
      if (item.uid === remoteStream.getId()) {
        item.video = remoteStream.video;
        item.screen = remoteStream.screen;
        displayName = item.name;
        item.audio = remoteStream.audio;
      }
      return item;
    })

    store.commit("setMeetingQueue", meetingQueue);
    resolve("（多人）对方改变了发布状态：" + "成员名:" + displayName + ";audio:" + remoteStream.audio + ";video:" + remoteStream.video + ";screen:" + remoteStream.screen)
  })
}

// 根据房间用户音量改变改变响应UI
const changeUiByVolume = (uid, level) => {
  /*获取当前聊天室中的聊天列表的人员信息*/
  let meetingQueue = store.state.multimedia_meetingmode.meetingQueue.slice(0);
  meetingQueue = meetingQueue.map(item => {
    if (item.uid === uid) {
      item.volumeLevel = level * 5;
    } else {
      item.volumeLevel = 0;
    }
    return item;
  })
  store.commit("setMeetingQueue", meetingQueue);
}

// 获取token
const initGetToken = () => {
  return getToken({
    uid: store.state.multimedia_meetingmode.substituteUserId || store.getters.userInfo.id,
    appkey: config.appkey,
    appSecret: config.appSecret,
    channelName: store.state.multimedia_meetingmode.meetingChannelName
  }).then(token => {
    return token
  }, (e) => {
    throw e;
  });
};