var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "widgetContent",
      class:
        _vm.meetingWindowStyle == "normalStyle"
          ? "normal"
          : _vm.meetingWindowStyle == "largestStyle"
          ? "largest"
          : "mini"
    },
    [
      _c("div", { staticClass: "positionBox" }, [
        _c("div", { staticClass: "monitorAudioStatusRecord" }, [
          _c("div", {
            staticClass: "speedOfProgress",
            style: { height: _vm.volumeLevel + "%" }
          })
        ])
      ]),
      _c("div", { staticClass: "monitorAudioStatusBg" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }