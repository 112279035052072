<template>
  <div
    class="widgetContent"
    :class="
      meetingWindowStyle == 'normalStyle'
        ? 'normal'
        : meetingWindowStyle == 'largestStyle'
        ? 'largest'
        : 'mini'
    "
  >
    <div class="positionBox">
      <div class="monitorAudioStatusRecord">
        <div
          class="speedOfProgress"
          :style="{ height: volumeLevel + '%' }"
        ></div>
      </div>
    </div>
    <div class="monitorAudioStatusBg"></div>
  </div>
</template>
<script>
export default {
  name: "MonitorSelfAudioStatus",
  props: {
    volumeLevel: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    // 当前窗口的展示类型
    meetingWindowStyle() {
      return this.$store.state.multimedia_meetingmode.meetingWindowStyle;
    },
  },
};
</script>
<style lang="stylus" scoped>
.largest {
  transform scale(0.94);
  height: 88%;
}
.normal {
  transform scale(0.74);
}
.mini {
  transform:scale(0.6)
}
.widgetContent{
  height 100%;
  margin auto;
  border-radius 50%;
  box-sizing border-box;
  .positionBox{
    width 15px;
    height 13px;
    display flex;
    flex-direction row;
    align-items center;
    justify-content center;
    .monitorAudioStatusRecord{
      height 13px;
      width 7px;
      border-radius 6px;
      background #ffffff;
      display flex;
      overflow hidden;
      flex-direction column-reverse;
      align-items center;
      justify-content flex-start;
      .speedOfProgress{
        width 100%;
        background #29D652;
        transition height 0.2s;
      }
    }
  }
  .monitorAudioStatusBg{
    width 15px;
    height 12px;
    position relative;
    bottom 4px;
    background url("../../../../assets/images/multiplayer/monitorAudioStatusBg.png") no-repeat;
    background-size 100% 100%;
    transition transform 0.3s;
  }
}
</style>
