<template>
  <div class="memberList">
    <div
      class="memberItem"
      :class="itemStyle"
      v-for="memberInfo in meetingQueue"
      :key="memberInfo.uid"
      :style="{
        border:
          membersOnTheHomeScreen === memberInfo.uid
            ? '2px solid #52BD68'
            : 'none',
      }"
    >
      <div
        class="memberShow"
        ref="memeberVideo"
        :id="membersOnTheHomeScreen === memberInfo.uid ? 'xxyy' : ''"
        :data-uid="memberInfo.uid"
        @click.stop="enlargeTheVideo(memberInfo.uid)"
      >
        <el-image
          class="memberImage"
          :src="formartImageUrl(memberInfo.profilePhoto)"
          :fit="'cover'"
          v-if="
            (membersOnTheHomeScreen === memberInfo.uid &&
              (!memberInfo.video || !memberInfo.screen)) ||
              (!memberInfo.video && !memberInfo.screen)
          "
        >
          <div slot="error" class="imageLoadError">
            <span
              class="iconfont_Me iconStyle"
              :class="isGroupHeader ? 'icon-group-fill' : 'icon-user-fill'"
            ></span></div
        ></el-image>
      </div>
      <div class="initiatorIcon">
        <el-image
          style="width: 100%; height: 100%; display: block"
          :src="
            require('../../../../assets/images/newMeetingMultiplayer/initiatorIcon.png')
          "
          v-if="memberInfo.uid === meetingCreatorId"
          :fit="'fill'"
        >
        </el-image>
      </div>
      <!-- <div
        class="enlargeIcon"
        @click.stop="enlargeTheVideo(memberInfo.uid)"
      ></div> -->
      <div v-if="memberInfo.vipStatus" class="vipIcon">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('paidMember')"
          placement="bottom"
          popper-class="me-tooltip-effect"
        >
          <VipIcon
            :vipStatus="memberInfo.vipStatus"
            :iconWidth="14"
            :iconHeight="14"
            :id="memberInfo.uid"
          />
        </el-tooltip>
      </div>
      <div class="memberInfo">
        <div class="microphoneBox">
          <MonitorSelfAudioStatus
            :volumeLevel="memberInfo.volumeLevel"
            v-if="memberInfo.audio"
          >
          </MonitorSelfAudioStatus>
          <img
            v-else
            class="microphoneCloseStyle"
            :src="
              require('../../../../assets/images/newMeetingMultiplayer/microphoneCloseIcon.png')
            "
          />
        </div>
        <div
          class="memberName"
          :title="memberInfo.name"
          :class="memberInfo.vipStatus ? 'xvipName' : ''"
        >
          {{ memberInfo.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MonitorSelfAudioStatus from "../widget/monitorSelfAudioStatus";
import VipIcon from "@/newVersion/components/icons/IconVip.vue";
import {
  initialMemberMonitor,
  changeUiByState,
  playOtherMemberVideo,
} from "../coreOperations/client";
export default {
  components: {
    MonitorSelfAudioStatus,
    VipIcon,
  },
  props: {
    // 窗口宽度状态，小窗口状态时显示为
    screenSizeForMeeting: {
      type: String,
      default: "largeScreen",
    },
    // 是否是群头像
    isGroupHeader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // 当前成员列表队列数据
    meetingQueue() {
      return this.$store.state.multimedia_meetingmode.meetingQueue;
    },
    // 当前在主屏展示的人员ID
    membersOnTheHomeScreen() {
      return this.$store.state.multimedia_meetingmode.membersOnTheHomeScreen;
    },
    // 会议发起者ID
    meetingCreatorId() {
      return this.$store.state.multimedia_meetingmode.meetingCreatorId;
    },
    // 根据不同状态展示不同样式(itemStyle1：会议室为窄屏模式时展示的样式，itemStyle2：会议室为正常模式时展示的样式，itemStyle3：会议室为最大展开状态时的样式)
    itemStyle() {
      let itemStyle = "";
      if (
        this.$store.state.multimedia_meetingmode.meetingWindowStyle ===
        "normalStyle"
      ) {
        itemStyle =
          this.screenSizeForMeeting === "largeScreen"
            ? "itemStyle2"
            : "itemStyle1";
      } else if (
        this.$store.state.multimedia_meetingmode.meetingWindowStyle ===
        "largestStyle"
      ) {
        itemStyle = "itemStyle3";
      }
      return itemStyle;
    },
  },
  watch: {
    // 监听主屏展示人员的变化
    membersOnTheHomeScreen: {
      handler(val, old) {
        // 分开写方便看
        // 主屏从成员视频切换到成员视频，成员视频重新在这里播放
        if (val != old && val != null && old != null) {
          this.playVideoForId(old);
        }
        // 主屏从成员视频切换到本地视频，成员视频重新在这里播放
        if (val != old && val == null) {
          this.playVideoForId(old);
        }
      },
      deep: true,
    },
  },
  updated() {
    if (this.$refs.memeberVideo) {
      initialMemberMonitor([...this.$refs.memeberVideo])
        .then((e) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  },
  methods: {
    // 在其对应ID的对应的位置上播放对应视频
    playVideoForId(memberId) {
      return new Promise((resolve, reject) => {
        let meetingRemoteStreams = this.$store.state.multimedia_meetingmode
          .meetingRemoteStreams;
        // 获取之前展示的成员的流对象
        let needShowMember = meetingRemoteStreams.filter(
          (item) => item.getId() === memberId
        );
        // 获取之前展示的成员在列表中的元素位置
        let playerNode = [...this.$refs.memeberVideo].filter(
          (item) => item.dataset.uid === memberId
        );
        if (needShowMember.length != 0 && playerNode.length != 0) {
          changeUiByState(needShowMember[0])
            .then((result) => {
              playOtherMemberVideo(needShowMember[0], playerNode[0])
                .then((result) => {})
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    // 放大视频
    enlargeTheVideo(uid) {
      if (this.membersOnTheHomeScreen !== uid) {
        // 如果是用户自己，就将主屏展示设置为null，如果是其他成员，就设置为他的ID
        this.$store.commit("setMembersOnTheHomeScreen", uid);
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.memberList {
  width: 100%;
  overflow: hidden;

  .itemStyle1 {
    height: 74px;

    .initiatorIcon {
      width: 12px;
      height: 12px;
      overflow: hidden;
      top: 2px;
      left: 4px;
    }

    .enlargeIcon {
      top: 4px;
      right: 4px;
      width: 10px;
      height: 10px;
      background: url('../../../../assets/images/newMeetingMultiplayer/selectIcon.png') no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
    .vipIcon{
      top: -1px;
      right: 4px;
      cursor: pointer;
    }

    .memberInfo {
      height: 18px;
      border-radius: 9px;
      max-width: 64px;
      left: 5px;
      bottom: 6px;
      padding: 0 8px 0 24px;
      .microphoneBox {
        width:22px;
        height: 22px;
        .microphoneCloseStyle {
          width: 14px;
          height: 14px;
          display: block;
        }
      }

      .memberName {
        flex: 1;
        font-size: 10px;
        color: #FFFFFF;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .itemStyle2 {
    height: 84px;

    .initiatorIcon {
      width: 14px;
      height: 14px;
      overflow: hidden;
      top: 3px;
      left: 5px;
    }

    .enlargeIcon {
      top: 6px;
      right: 6px;
      width: 12px;
      height: 12px;
      background: url('../../../../assets/images/newMeetingMultiplayer/selectIcon.png') no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }

    .vipIcon{
      top: 1px;
      right: 4px;
      cursor: pointer;
      svg{
        width 14px
        height 14px
      }
    }

    .memberInfo {
      height: 18px;
      border-radius: 9px;
      max-width: 74px;
      left: 5px;
      bottom: 6px;
      padding: 0 5px 0 27px;
      .microphoneBox {
        width:25px;
        height: 25px;
        .microphoneCloseStyle {
          width: 15px;
          height: 15px;
          display: block;
        }
      }

      .memberName {
        flex: 1;
        font-size: 10px;
        color: #FFFFFF;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .itemStyle3 {
    height: 120px;

    .initiatorIcon {
      width: 18px;
      height: 18px;
      overflow: hidden;
      top: 9px;
      left: 8px;
    }

    .enlargeIcon {
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      background: url('../../../../assets/images/newMeetingMultiplayer/selectIcon_large.png') no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }

    .vipIcon{
      top: 4px;
      right: 4px;
      cursor: pointer;
        svg{
          width 18px
          height 18px
        }
    }

    .memberInfo {
      height: 22px;
      border-radius: 11px;
      max-width: 158px;
      left: 10px;
      bottom: 7px;
      padding: 0 9px 0 30px;
      .microphoneBox {
        width:28px;
        height: 28px;
        .microphoneCloseStyle {
          width: 16px;
          height: 16px;
          display: block;
        }
      }

      .memberName {
        flex: 1;
        font-size: 10px;
        color: #FFFFFF;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .memberItem {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;

    &:first-child {
      margin-top: 12px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .memberShow {
      width: 100%;
      height: 100%;

      .memberImage {
        width: 100%;
        height: 100%;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .memeberVideo {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .initiatorIcon {
      position: absolute;
      z-index: 2;
    }

    .enlargeIcon {
      position: absolute;
      z-index: 2;
    }
    .vipIcon{
      position: absolute;
      z-index: 2;
    }

    .memberInfo {
      background: rgba(0, 0, 0, 0.8);
      position: absolute;
      z-index: 2;
      // overflow: hidden;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .microphoneBox {
        border-radius: 50%;
        left:0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.8);
        position: absolute;
        border: 1px solid #4d4d4d;
        // height: 100%;
        flex-shrink: 0;
        // padding-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
<style lang="stylus">
.memberList
  .memberItem
    .memberShow
      .memberImage
        .imageLoadError
          width 100%;
          height 100%;
          border-radius 50%;
          background #E4E5E9;
          display flex;
          align-items center;
          justify-content center;
          .iconStyle
            font-size 24px;
            color #999999
</style>
