<template>
  <div
    class="meetingRoom"
    v-customDrag
    v-if="$store.state.multimedia_meetingmode.meetingWindowStatus"
    :class="
      $store.state.multimedia_meetingmode.meetingWindowStyle === 'largestStyle'
        ? 'largestStyle'
        : ''
    "
    ref="meetingRoom"
    :style="{
      top: windowTopCoordinate,
      left: windowLeftCoordinate,
      width: windowWidth,
      height: windowHeight,
      zIndex:
        $store.state.multimedia_meetingmode.meetingWindowStyle === 'smallStyle'
          ? 2001
          : 2000,
    }"
  >
    <div class="dragOperationArea">
      <!-- !this.$store.state.multimedia_meetingmode.localStreamIsReady && !OVERTIME -->
      <div
        v-if="
          !this.$store.state.multimedia_meetingmode.localStreamIsReady &&
            !OVERTIME
        "
        class="loading-box"
        style="
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        "
      >
        <div
          style="position: absolute;top:14px;right:14px;cursor: pointer;"
          @click.stop="closeThisWindow"
        >
          <span class="el-icon-close" style="color:#fff"></span>
        </div>
        <div
          class="outer-box Rotation"
          style="
            width: 38px;
            height: 38px;
            background: #fff;
            border-radius: 50%;
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            color: #b889d2;
            animation: rotation 6s linear infinite;
          "
        >
          <i class="el-icon-refresh-left Rotation"></i>
        </div>
      </div>
      <!-- 加载失败，重新加载,超60s算加载失败，显示这个 -->
      <div v-if="OVERTIME" class="loading-failed-box">
        <div
          style="position: absolute;top:14px;right:14px;cursor: pointer;"
          @click.stop="closeThisWindow"
        >
          <span class="el-icon-close" style="color:#fff"></span>
        </div>
        <img src="../../../assets/images/im/loadingFailed.png" alt="" />
        <div class="reload-btn" @click="refresh">{{ $t("reload") }}</div>
      </div>

      <MeetingRoom
        :sdkReady="sdkReady"
        :screenSizeForMeeting="screenSizeForMeeting"
        v-show="
          $store.state.multimedia_meetingmode.meetingWindowStyle !==
            'smallStyle'
        "
      ></MeetingRoom>
      <div
        class="miniShow"
        @mouseup="gomouseup"
        @mousedown="gomousedown($event)"
        @mousemove="gomousemove"
        v-show="
          $store.state.multimedia_meetingmode.meetingWindowStyle ===
            'smallStyle'
        "
      >
        <div
          class="hearder"
          v-if="!$store.state.multimedia_meetingmode.userSetCameraStatus"
        >
          <div class="memberShow" ref="localVideo" :data-uid="localInfo.uid">
            <div class="memberImageBox">
              <el-image
                draggable="false"
                class="memberImage"
                :src="formartImageUrl(localInfo.profilePhoto)"
                :fit="'cover'"
              ></el-image>
              <div class="isInitiator"></div>
            </div>
            <div class="memberText">
              <div class="microphoneBox">
                <MonitorSelfAudioStatus
                  :volumeLevel="
                    $store.state.multimedia_meetingmode.meetingVolume
                  "
                  v-if="memberMicrophoneStatus"
                ></MonitorSelfAudioStatus>
                <img
                  v-else
                  class="microphoneCloseStyle"
                  :src="
                    require('../../../assets/images/newMeetingMultiplayer/microphoneCloseIcon.png')
                  "
                />
              </div>
              <span
                class="textStyle"
                :class="localInfo.vipStatus ? 'cvipName' : ''"
                >{{ localInfo.name }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('paidMember')"
                  placement="bottom"
                  popper-class="me-tooltip-effect"
                >
                  <VipIcon
                    class="icon"
                    style="cursor:pointer;margin-left: 1px;margin-top: 1px;"
                    :vipStatus="localInfo.vipStatus"
                    :iconWidth="14"
                    :iconHeight="14"
                    :id="localInfo.id"
                  />
                </el-tooltip>
              </span>
            </div>
          </div>
        </div>
        <div
          v-show="$store.state.multimedia_meetingmode.userSetCameraStatus"
          id="miniScreen"
          style="width: 100%; height: 100%"
        ></div>
        <div
          class="memberText"
          v-show="$store.state.multimedia_meetingmode.userSetCameraStatus"
          style="position: absolute; left: 15px; bottom: 15px"
        >
          <div class="microphoneBox">
            <MonitorSelfAudioStatus
              :volumeLevel="$store.state.multimedia_meetingmode.meetingVolume"
              v-if="memberMicrophoneStatus"
            ></MonitorSelfAudioStatus>
            <img
              v-else
              class="microphoneCloseStyle"
              :src="
                require('../../../assets/images/newMeetingMultiplayer/microphoneCloseIcon.png')
              "
            />
          </div>
          <span class="textStyle" :class="localInfo.vipStatus ? 'cvipName' : ''"
            >{{ localInfo.name }}
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('paidMember')"
              placement="bottom"
              popper-class="me-tooltip-effect"
            >
              <VipIcon
                class="icon"
                style="cursor:pointer;margin-left: 1px;"
                :vipStatus="localInfo.vipStatus"
                :iconWidth="14"
                :iconHeight="14"
                :id="localInfo.id"
              />
            </el-tooltip>
          </span>
        </div>

        <!-- 操作部分 -->
        <div class="commontiming switchSizeBox">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('reduction')"
            placement="bottom-start"
          >
            <i>
              <SizeButton :buttonType="'full'" :color="'white'"></SizeButton>
            </i>
          </el-tooltip>
          <span class="line"></span>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('maximize')"
            placement="bottom-start"
          >
            <i @click.stop="hanging">
              <SizeButton :color="'white'"></SizeButton>
            </i>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MeetingRoom from "./room.vue";
import { initializeSDK } from "./coreOperations/client";
import VipIcon from "@/newVersion/components/icons/IconVip";
import {
  runMeetingTimer,
  meetingStartRecord,
  hangUpTheCall,
} from "./coreOperations/local";
import MonitorSelfAudioStatus from "./widget/monitorSelfAudioStatus";
import * as RESTApi from "@/api/rest";
import SizeButton from "@/newVersion/components/im_size_button";
export default {
  components: {
    MeetingRoom,
    MonitorSelfAudioStatus,
    VipIcon,
    SizeButton,
  },
  data() {
    return {
      // 窗口初始化的Y坐标位置
      positionTop: 0,
      // 窗口初始化的X坐标位置
      positionLeft: 0,
      // 窗口展示宽度状态（smallScreen: 小屏幕窗口状态；largeScreen：大屏幕窗口状态）
      screenSizeForMeeting: "largeScreen",
      // 用于通知子组件音视频SDK准备完毕
      sdkReady: false,
      // 最小化鼠标按下标记
      mousedownFlag: false,
      // 点击拖动判断
      timmerHandle: null,
      // 初始化超时
      OVERTIME: false,
    };
  },
  computed: {
    // 会议计时
    meetingTimingText() {
      return this.$store.state.multimedia_meetingmode.meetingTimingText;
    },
    // 当前窗口的展示类型
    meetingWindowStyle() {
      return this.$store.state.multimedia_meetingmode.meetingWindowStyle;
    },
    // 窗口当前真正显示的宽度
    windowWidth() {
      let windowWidth = `${this.normalWidth}px`;
      if (this.meetingWindowStyle === "smallStyle") {
        windowWidth = "224px";
      } else if (this.meetingWindowStyle === "largestStyle") {
        windowWidth = "100%";
      }
      return windowWidth;
    },
    // 窗口当前真正显示的高度
    windowHeight() {
      let windowHeight = `${this.normalHeight}px`;
      if (this.meetingWindowStyle === "smallStyle") {
        windowHeight = "126px";
      } else if (this.meetingWindowStyle === "largestStyle") {
        windowHeight = "100%";
      }
      return windowHeight;
    },
    // 窗口当前相对于屏幕顶部的距离
    windowTopCoordinate() {
      let windowTopCoordinate = "auto";
      // 如果当前窗口为mini悬窗的时候
      if (this.meetingWindowStyle === "smallStyle") {
        windowTopCoordinate = "144px";
      } else if (this.meetingWindowStyle === "normalStyle") {
        windowTopCoordinate = `${this.positionTop}px`;
      }
      return windowTopCoordinate;
    },
    // 窗口当前相对于屏幕左侧的距离
    windowLeftCoordinate() {
      let windowLeftCoordinate = "auto";
      // 如果当前窗口为mini悬窗的时候
      if (this.meetingWindowStyle === "smallStyle") {
        windowLeftCoordinate = `${document.documentElement.clientWidth -
          122 -
          125}px`;
      } else if (this.meetingWindowStyle === "normalStyle") {
        windowLeftCoordinate = `${this.positionLeft}px`;
      }

      return windowLeftCoordinate;
    },
    // 正常窗口根据屏幕大小应该显示的宽度
    normalWidth() {
      let normalWidth = 728;
      if (this.screenSizeForMeeting === "smallScreen") {
        normalWidth = 397;
      }
      return normalWidth;
    },
    // 正常窗口根据屏幕大小应该显示的高度
    normalHeight() {
      let normalHeight = 518;
      normalHeight = 650;
      if (this.screenSizeForMeeting === "smallScreen") {
        normalHeight = document.documentElement.clientWidth <= 1400 ? 590 : 650;
      }
      return normalHeight;
    },
    // 本地用户自己信息
    localInfo() {
      return {
        name: this.$store.getters.userInfo.name,
        profilePhoto: this.$store.getters.userInfo.avatar,
        uid: this.$store.getters.userInfo.id,
        video: this.$store.state.multimedia_meetingmode.userSetCameraStatus,
        audio: this.$store.state.multimedia_meetingmode.userSetMicrophoneStatus,
        volumeLevel:
          this.$store.state.multimedia_meetingmode.meetingVolume || 0,
        isSelfInfo: true,
        vipStatus: this.$store.getters.userInfo.vipStatus,
      };
    },
    // 是否展示麦克风图标
    memberMicrophoneStatus() {
      return this.$store.state.multimedia_meetingmode.userSetMicrophoneStatus;
    },
  },
  mounted() {
    // 根据屏幕宽度设置会议窗口宽度
    window.addEventListener(
      "resize",
      async () => {
        await this.initWindowPosition();
      },
      false
    );
    this.xx && clearTimeout(this.xx);
    // 初始化窗口的位置
    this.initWindowPosition();
    // 每次进来先重置SDK加载状态一下
    this.sdkReady = false;
    this.OVERTIME = false;
    this.$nextTick(() => {
      //初始化的时候，缓存一下上一次的生成的房间ID号
      this.$store.commit(
        "setOldMeetingGroupId",
        this.$store.state.multimedia_meetingmode.meetingGroupId
      );
      this.initWebRTCSdk();
      // 开启定时器，初始化15s,按初始化失败处理
      this.xx = setTimeout(() => {
        if (!this.$store.state.multimedia_meetingmode.localStreamIsReady) {
          this.OVERTIME = true;
        }
      }, 20000);
    });
  },
  beforeDestroy() {
    window.removeEventListener(
      "resize",
      async () => {
        await this.initWindowPosition();
      },
      false
    );
  },
  methods: {
    // 初始化音视频远程客户端
    initWebRTCSdk() {
      initializeSDK()
        .then((result) => {
          // 开始会议计时
          runMeetingTimer();
          this.$nextTick(() => {
            // 通知子组件可以开始初始化了
            this.sdkReady = true;
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    // 最小化窗口时，判断是拖动还是点击事件
    gomousedown(ev) {
      if (ev.srcElement.nodeName == "path") {
        this.handleClickVip();
        return false;
      }
      this.mousedownFlag = true;
    },
    gomousemove() {
      // 如果鼠标已经按下，才开始触发拖动事件
      if (this.mousedownFlag) {
        if (this.timmerHandle == null) {
          this.timmerHandle = setTimeout(() => {
            this.mousedownFlag = false;
          }, 200);
        }
      }
    },
    gomouseup() {
      clearTimeout(this.timmerHandle);
      this.timmerHandle = null;
      if (this.mousedownFlag) {
        this.mousedownFlag = false;
        this.setNormalWindow();
      }
    },
    // 初始化窗口坐标位置
    async initWindowPosition() {
      let screenWidth = document.documentElement.clientWidth;
      let screenHeight = document.documentElement.clientHeight;
      if (screenWidth <= 1400) {
        this.positionTop = screenHeight - 590;
      } else {
        this.positionTop = screenHeight - 650;
      }
      // 根据屏幕宽度设置窗口大小
      await this.initWindowSize(screenWidth);
      let windowWidth = 0;
      if (this.meetingWindowStyle === "largestStyle") {
        windowWidth = screenWidth;
      } else {
        windowWidth = this.windowWidth.substr(0, this.windowWidth.length - 2);
      }
      let remainingWidthOnLeft = screenWidth - 960 - windowWidth - 36;
      if (remainingWidthOnLeft > 0) {
        this.positionLeft = remainingWidthOnLeft;
      } else {
        this.positionLeft = 0;
      }
    },
    // 将窗口重置为普通窗口
    setNormalWindow() {
      this.$store.commit("setMeetingWindowStyle", "normalStyle");
      this.$store.state.imStore.IMWindowSize === "full" &&
        this.$store.commit("setIMWindowSize", "medium");
    },
    // 根据屏幕大小设置窗口宽高度
    initWindowSize(screenWidth) {
      return new Promise((resolve, reject) => {
        // 获取屏幕剩余宽度
        let remainingWidth = screenWidth - 960;
        if (remainingWidth < 728) {
          this.screenSizeForMeeting = "smallScreen";
        } else {
          this.screenSizeForMeeting = "largeScreen";
        }
        this.$forceUpdate();
        this.$nextTick(() => {
          resolve("success");
        });
      });
    },
    hanging() {
      this.$store.commit("setIMWindowSize", "mini");
      this.$nextTick(() => {
        this.$store.commit("setMeetingWindowStyle", "largestStyle");
        document.querySelector(".imelink-container--miniscreen").style.zIndex =
          "2001";
      });
    },
    refresh() {
      this.$nextTick(() => {
        // 如果当前是开启了会议的，则获取会议的分享地址
        if (this.$store.state.multimedia_meetingmode.meetingWindowStatus) {
          // 先获取会议地址，再挂断
          this.shareMeetingLink().then((meetingAddress) => {
            this.hangupTheCall()
              .then((result) => {
                this.$store.commit("setMeetingType", "createNewUser");
                // 将会议页面刷新设置为false
                let meetingRoomId = meetingAddress.match(
                  /meeting\/(\S{16})/
                )[1];
                // localStorage.setItem("meetingReloadFlag", "refresh");
                localStorage.setItem("meetingRoomId", meetingRoomId);
                console.log("正常退出");
                setTimeout(() => {
                  this.$router.replace({
                    path: `/meeting/${meetingRoomId}`,
                  });
                }, 1000);
              })
              .catch(async () => {
                let params = {
                  chatType: "groupChat",
                  subjectId: this.$store.state.multimedia_meetingmode
                    .meetingGroupId,
                };
                let result = await RESTApi.exitMeeting(params);
                if (result.code == 200) {
                  this.$store.commit("setMeetingType", "createNewUser");
                  let meetingRoomId = meetingAddress.match(
                    /meeting\/(\S{16})/
                  )[1];
                  // localStorage.setItem("meetingReloadFlag", "refresh");
                  localStorage.setItem("meetingRoomId", meetingRoomId);
                  console.log("异常退出");
                  setTimeout(() => {
                    this.$router.replace({
                      path: `/meeting/${meetingRoomId}`,
                    });
                  }, 1000);
                }
              });
          });
        } else {
          // 如果是旧版的即时通讯窗口开启，则执行旧版挂断操作
          forcedHangUp()
            .then(async (result) => {
              window.location.reload();
            })
            .catch((err) => {});
        }
      });
    },
    // 挂断会议
    async hangupTheCall() {
      return new Promise(async (resolve, reject) => {
        // 先通知后台再挂断网易云信  meetingGroupId
        let params = {
          chatType: "groupChat",
          subjectId: this.$store.state.multimedia_meetingmode.meetingGroupId,
        };
        let result = await RESTApi.exitMeeting(params);
        if (result.code == 200) {
          hangUpTheCall()
            .then((e) => {
              // 处理完数据后，将窗口关闭
              this.$store.commit("setMeetingWindowStatus", false);
              resolve("success");
            })
            .catch((e) => {
              this.$store.commit("setMeetingWindowStatus", false);
              reject(e);
              resolve("success");
            });
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
          reject(result.message);
        }
      });
    },
    // 获取分享会议链接地址
    shareMeetingLink() {
      return new Promise(async (resolve, reject) => {
        let params = {
          groupId: this.$store.state.multimedia_meetingmode.meetingGroupId,
          meetingId: this.$store.state.multimedia_meetingmode
            .meetingChannelName,
        };
        let result = await RESTApi.getShareAddress(params);
        if (result.code == 200) {
          resolve(result.data.data.meetingAddress);
        } else {
          // this.$message({
          //   type: "error",
          //   message: result.message,
          // });
          reject(result.message);
        }
      });
    },
    //一直加载中的关闭按钮
    closeThisWindow() {
      this.hangupTheCall();
    },
  },

  beforeDestroy() {
    clearTimeout(this.xx);
  },
};
</script>
<style lang="stylus" scoped>
.meetingRoom {
  position: fixed;
  overflow: hidden;
  user-select: none;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  background: #282828;
  border-radius: 10px;
  z-index: 2000;

  .dragOperationArea {
    width: 100%;
    height: 100%;

    .testshow {
      width: 100%;
      height: 100%;
    }

    .miniShow {
      width: 100%;
      height: 100%;
      cursor: pointer;
      // background: #52BD68;
      background: #282828;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      .timingText {
        color: #FFFFFF;
        font-size: 12px;
        width: 100%;
        line-height: 18px;
        font-weight: bold;
        text-align: center;
      }

      .meetingIcon {
        width: 54px;
        height: 54px;
        line-height: 54px;
        text-align: center;

        .iconStyle {
          font-size: 45px;
          color: #FFFFFF;
        }
      }

      .hearder {
        .memberShow {
          text-align: center;

          .memberImageBox {
            position: relative;

            .isInitiator {
              width: 16px;
              height: 16px;
              position: absolute;
              bottom: 6px;
              right: 4px;
              z-index: 2;
              background: url('../../../assets/images/newMeetingMultiplayer/initiatorIcon_large.png') no-repeat;
              background-size: 100% 100%;
            }
          }
        }

        .memberImage {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          // border: 1px solid #fff;
        }
      }

      .memberText {
        padding: 0 6px 0 0;
        height: 18px;
        font-size: 12px;
        color: #ffffff;
        background: rgba(255, 255, 255, 0.22);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        margin-top: 4px;

        .microphoneBox{
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.8);
          border: 1px solid #4d4d4d;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          .microphoneCloseStyle {
            width: 14px;
            height: 14px;
          }
        }


        .textStyle {
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .switchSizeBox {
        position: absolute;
        opacity: 0;
        height: 26px;
        padding: 0 10px;
        background: #000000;
        border-radius: 12px;
        top: 12px;
        right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #FFFFFF;

        .line {
          width: 1px;
          height: 12px;
          background: rgba(255, 255, 255, 0.4);
          margin: 0 13px;
          // border: 1px solid ;
        }

        i {
          cursor: pointer;
          font-size: 14px;
        }
      }

      &:hover {
        .switchSizeBox {
          opacity: 1;
        }
      }
    }
  }
}

// 加载失败的样式
.loading-failed-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .reload-btn {
    width: 106px;
    opacity: 0.6;
    color: #52bd68;
    font-size: 12px;
    border: 1px solid #52bd68;
    border-radius: 18px;
    text-align: center;
    padding: 6px 0;
    margin-top: 20px;
    cursor: pointer;

    &:hover {
      border-color: #71747a;
      color: #fff;
    }
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(360deg);
  }

  to {
    -webkit-transform: rotate(0deg);
  }
}

.Rotation {
  animation: rotation 2s linear infinite;
}
</style>
