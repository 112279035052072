var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "operatingBox",
      class: _vm.operatingStyle,
      style: {
        opacity: _vm.needToHide ? 0 : 1,
        width: `${_vm.operatingWidth}px`
      }
    },
    [
      _c(
        "div",
        { staticClass: "buttonsBox" },
        _vm._l(_vm.operatingButtons, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "buttonStyle",
              style: {
                top: `${item.top}px`,
                left: `${item.left}px`,
                width: item.value != "hangUp" ? "45px" : "46px",
                height: item.value != "hangUp" ? "45px" : "46px"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  item.value != "hangUp"
                    ? _vm.clickOperating(item.value)
                    : _vm.hangupTheCall()
                }
              }
            },
            [
              item.value == "showChat" && _vm.unread != 0
                ? _c("div", { staticClass: "unread" })
                : _vm._e(),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    placement: "top",
                    effect: "dark",
                    content: item.label
                  }
                },
                [
                  _c("el-image", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { src: item.imageSrc, fit: "fill" }
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        0
      ),
      _vm.operatingStyle == "largeOperating" ||
      _vm.operatingStyle == "hideOperating"
        ? _c(
            "el-tooltip",
            {
              attrs: {
                content: _vm.boxMinimize ? _vm.$t("show") : _vm.$t("hide"),
                placement: "top",
                effect: "dark"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "controlButton",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.changeBoxSize.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("span", {
                    staticClass: "iconfont_Me iconStyle",
                    class: _vm.boxMinimize ? "icon-up" : "icon-down"
                  })
                ]
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }