<template>
  <div class="roomList" :style="listStyle">
    <div class="rollBox" v-if="showRollButton" @click.stop="startRoll('up')">
      <img
        :src="
          require('../../../../assets/images/newMeetingMultiplayer/rollUp.png')
        "
        :style="{
          width: meetingWindowStyle === 'normalStyle' ? '15px' : '20px',
          display: 'block',
          objectFit: 'fill',
        }"
      />
    </div>
    <div class="listBox" ref="listBox">
      <div class="memberItemBox" ref="memberItemBox">
        <LocalItem
          v-if="sdkIsReady"
          class="memberItem"
          :localInfo="selfInfo"
          :screenSizeForMeeting="screenSizeForMeeting"
        ></LocalItem>
        <MemberList
          :screenSizeForMeeting="screenSizeForMeeting"
          v-if="meetingQueue.length !== 0 && sdkIsReady"
        ></MemberList>
      </div>
    </div>
    <div class="rollBox" v-if="showRollButton" @click.stop="startRoll('down')">
      <img
        :src="
          require('../../../../assets/images/newMeetingMultiplayer/rollDown.png')
        "
        :style="{
          width: meetingWindowStyle === 'normalStyle' ? '15px' : '20px',
          display: 'block',
          objectFit: 'fill',
        }"
      />
    </div>
    <div class="operationKeysBox">
      <div
        class="keysIcon"
        @click.stop="changeMemberList"
        :class="listStatus ? 'puAwayIconStyle' : 'unfoldIconStyle'"
      ></div>
    </div>
  </div>
</template>
<script>
import LocalItem from "./localItem";
import MemberList from "./memberList";
export default {
  components: {
    LocalItem,
    MemberList,
  },
  props: {
    // 窗口宽度状态，小窗口状态时显示为
    screenSizeForMeeting: {
      type: String,
      default: "large",
    },
    // 列表栏是否展开
    listStatus: {
      type: Boolean,
      default: true,
    },
    // 监听音视频SDK是否准备完毕
    sdkReady: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    // 监听音视频SDK是否准备完毕
    sdkReady: {
      handler(val, old) {
        if (val && val !== old) {
          this.sdkIsReady = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    // 当前成员列表队列数据
    meetingQueue() {
      return this.$store.state.multimedia_meetingmode.meetingQueue;
    },
    // 当前窗口展示的大小
    meetingWindowStyle() {
      return this.$store.state.multimedia_meetingmode.meetingWindowStyle;
    },
    // 根据不同状态展示不同样式(itemStyle1：会议室为窄屏模式时展示的样式，itemStyle2：会议室为正常模式时展示的样式，itemStyle3：会议室为最大展开状态时的样式)
    listStyle() {
      let listStyle = {};
      if (this.meetingWindowStyle === "normalStyle") {
        listStyle = {
          padding: "13px",
        };
      } else if (this.meetingWindowStyle === "largestStyle") {
        listStyle = {
          padding: "20px",
        };
      }
      return listStyle;
    },
    // 本地用户自己信息
    selfInfo() {
      // 创建了新用户 substituteUserInfo
      if (this.$store.state.multimedia_meetingmode.substituteUserId) {
        return {
          name: this.$store.state.multimedia_meetingmode.substituteUserInfo
            .name,
          profilePhoto: this.$store.getters.userInfo.avatar,
          uid: this.$store.state.multimedia_meetingmode.substituteUserInfo.id,
          video: this.$store.state.multimedia_meetingmode.userSetCameraStatus,
          audio: this.$store.state.multimedia_meetingmode
            .userSetMicrophoneStatus,
          volumeLevel:
            this.$store.state.multimedia_meetingmode.meetingVolume || 0,
          isSelfInfo: false,
          vipStatus: this.$store.getters.userInfo.vipStatus,
        };
      }
      return {
        name: this.$store.getters.userInfo.name,
        profilePhoto: this.$store.getters.userInfo.avatar,
        uid: this.$store.getters.userInfo.id,
        video: this.$store.state.multimedia_meetingmode.userSetCameraStatus,
        audio: this.$store.state.multimedia_meetingmode.userSetMicrophoneStatus,
        volumeLevel:
          this.$store.state.multimedia_meetingmode.meetingVolume || 0,
        isSelfInfo: true,
        vipStatus: this.$store.getters.userInfo.vipStatus,
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.whetherToShowTheButton();
    });
  },
  data() {
    return {
      // 展示滚动按钮
      showRollButton: false,
      // SDK准备好了再开始渲染列表
      sdkIsReady: false,
    };
  },
  methods: {
    // 开始滚动
    startRoll(type) {
      if (!this.$refs.listBox) {
        return;
      }
      switch (type) {
        case "up":
          this.$refs.listBox.scrollTo(0, this.$refs.listBox.scrollTop - 100);
          break;
        case "down":
          this.$refs.listBox.scrollTo(0, this.$refs.listBox.scrollTop + 100);
          break;
      }
    },
    // 是否需要展示滚动按钮
    whetherToShowTheButton() {
      if (
        this.$refs.memberItemBox.clientHeight > this.$refs.listBox.clientHeight
      ) {
        this.showRollButton = true;
      } else {
        this.showRollButton = false;
      }
    },
    // 点击改变成员列表状态
    changeMemberList() {
      this.$emit("changeMemberList", this.listStatus);
    },
  },
};
</script>
<style lang="stylus" scoped>
.roomList {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  .operationKeysBox {
    position: absolute;
    top: 0;
    right: -18px;
    width: 18px;
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .keysIcon {
      width: 18px;
      height: 85px;

      &:hover {
        cursor: pointer;
      }
    }

    .puAwayIconStyle {
      background: url('../../../../assets/images/newMeetingMultiplayer/multimediaOperationKeys_putAway.png') no-repeat;
      background-size: 100% 100%;
    }

    .unfoldIconStyle {
      background: url('../../../../assets/images/newMeetingMultiplayer/multimediaOperationKeys_unfold.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  .listBox {
    flex: 1;
    width: 100%;
    min-height: 0;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: rgba(100, 100, 100, 0.2);
      box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.2);
    }

    &::-webkit-scrollbar-track {
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    }

    .memberItemBox {
      width: 100%;
      min-height: 0;
      overflow: hidden;
    }
  }

  .rollBox {
    width: 100%;
    padding: 8px 0;
    flex-shrink: 0;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  }
}
</style>
<style lang="stylus">
.memberItem {
  .memberImage {
    .el-image__inner {
      height: 100% !important;
      width: auto !important;
      max-width: 100% !important;
    }
  }
}
</style>
