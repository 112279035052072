var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "roomList", style: _vm.listStyle }, [
    _vm.showRollButton
      ? _c(
          "div",
          {
            staticClass: "rollBox",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.startRoll("up")
              }
            }
          },
          [
            _c("img", {
              style: {
                width:
                  _vm.meetingWindowStyle === "normalStyle" ? "15px" : "20px",
                display: "block",
                objectFit: "fill"
              },
              attrs: {
                src: require("../../../../assets/images/newMeetingMultiplayer/rollUp.png")
              }
            })
          ]
        )
      : _vm._e(),
    _c("div", { ref: "listBox", staticClass: "listBox" }, [
      _c(
        "div",
        { ref: "memberItemBox", staticClass: "memberItemBox" },
        [
          _vm.sdkIsReady
            ? _c("LocalItem", {
                staticClass: "memberItem",
                attrs: {
                  localInfo: _vm.selfInfo,
                  screenSizeForMeeting: _vm.screenSizeForMeeting
                }
              })
            : _vm._e(),
          _vm.meetingQueue.length !== 0 && _vm.sdkIsReady
            ? _c("MemberList", {
                attrs: { screenSizeForMeeting: _vm.screenSizeForMeeting }
              })
            : _vm._e()
        ],
        1
      )
    ]),
    _vm.showRollButton
      ? _c(
          "div",
          {
            staticClass: "rollBox",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.startRoll("down")
              }
            }
          },
          [
            _c("img", {
              style: {
                width:
                  _vm.meetingWindowStyle === "normalStyle" ? "15px" : "20px",
                display: "block",
                objectFit: "fill"
              },
              attrs: {
                src: require("../../../../assets/images/newMeetingMultiplayer/rollDown.png")
              }
            })
          ]
        )
      : _vm._e(),
    _c("div", { staticClass: "operationKeysBox" }, [
      _c("div", {
        staticClass: "keysIcon",
        class: _vm.listStatus ? "puAwayIconStyle" : "unfoldIconStyle",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.changeMemberList.apply(null, arguments)
          }
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }