var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "memberList" },
    _vm._l(_vm.meetingQueue, function(memberInfo) {
      return _c(
        "div",
        {
          key: memberInfo.uid,
          staticClass: "memberItem",
          class: _vm.itemStyle,
          style: {
            border:
              _vm.membersOnTheHomeScreen === memberInfo.uid
                ? "2px solid #52BD68"
                : "none"
          }
        },
        [
          _c(
            "div",
            {
              ref: "memeberVideo",
              refInFor: true,
              staticClass: "memberShow",
              attrs: {
                id: _vm.membersOnTheHomeScreen === memberInfo.uid ? "xxyy" : "",
                "data-uid": memberInfo.uid
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.enlargeTheVideo(memberInfo.uid)
                }
              }
            },
            [
              (_vm.membersOnTheHomeScreen === memberInfo.uid &&
                (!memberInfo.video || !memberInfo.screen)) ||
              (!memberInfo.video && !memberInfo.screen)
                ? _c(
                    "el-image",
                    {
                      staticClass: "memberImage",
                      attrs: {
                        src: _vm.formartImageUrl(memberInfo.profilePhoto),
                        fit: "cover"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "imageLoadError",
                          attrs: { slot: "error" },
                          slot: "error"
                        },
                        [
                          _c("span", {
                            staticClass: "iconfont_Me iconStyle",
                            class: _vm.isGroupHeader
                              ? "icon-group-fill"
                              : "icon-user-fill"
                          })
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "initiatorIcon" },
            [
              memberInfo.uid === _vm.meetingCreatorId
                ? _c("el-image", {
                    staticStyle: {
                      width: "100%",
                      height: "100%",
                      display: "block"
                    },
                    attrs: {
                      src: require("../../../../assets/images/newMeetingMultiplayer/initiatorIcon.png"),
                      fit: "fill"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          memberInfo.vipStatus
            ? _c(
                "div",
                { staticClass: "vipIcon" },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: _vm.$t("paidMember"),
                        placement: "bottom",
                        "popper-class": "me-tooltip-effect"
                      }
                    },
                    [
                      _c("VipIcon", {
                        attrs: {
                          vipStatus: memberInfo.vipStatus,
                          iconWidth: 14,
                          iconHeight: 14,
                          id: memberInfo.uid
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "memberInfo" }, [
            _c(
              "div",
              { staticClass: "microphoneBox" },
              [
                memberInfo.audio
                  ? _c("MonitorSelfAudioStatus", {
                      attrs: { volumeLevel: memberInfo.volumeLevel }
                    })
                  : _c("img", {
                      staticClass: "microphoneCloseStyle",
                      attrs: {
                        src: require("../../../../assets/images/newMeetingMultiplayer/microphoneCloseIcon.png")
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "memberName",
                class: memberInfo.vipStatus ? "xvipName" : "",
                attrs: { title: memberInfo.name }
              },
              [_vm._v("\n        " + _vm._s(memberInfo.name) + "\n      ")]
            )
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }