var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "roomBox",
      on: {
        mousemove: _vm.mousemoveForoperate,
        mousedown: _vm.mousedownForoperate
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "leftBox",
          style: {
            width: `${_vm.leftBoxWidth}px`,
            transform: `translate3d(${_vm.leftBoxDisplacement},0,0)`
          }
        },
        [
          _c("RoomList", {
            attrs: {
              sdkReady: _vm.sdkReady,
              listStatus: _vm.listStatus,
              screenSizeForMeeting: _vm.screenSizeForMeeting
            },
            on: { changeMemberList: _vm.changeMemberList }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "rightBox",
          style: {
            paddingLeft: _vm.rightBoxPadding
          }
        },
        [
          _c("DisplayPanel", {
            attrs: {
              screenSizeForMeeting: _vm.screenSizeForMeeting,
              sdkReady: _vm.sdkReady
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }