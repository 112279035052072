<template>
  <div
    class="operatingBox"
    :style="{ opacity: needToHide ? 0 : 1, width: `${operatingWidth}px` }"
    :class="operatingStyle"
  >
    <div class="buttonsBox">
      <div
        class="buttonStyle"
        :style="{
          top: `${item.top}px`,
          left: `${item.left}px`,
          width: item.value != 'hangUp' ? '45px' : '46px',
          height: item.value != 'hangUp' ? '45px' : '46px',
        }"
        v-for="(item, index) in operatingButtons"
        :key="index"
        @click.stop="
          item.value != 'hangUp' ? clickOperating(item.value) : hangupTheCall()
        "
      >
        <div
          class="unread"
          v-if="item.value == 'showChat' && unread != 0"
        ></div>
        <el-tooltip
          placement="top"
          class="item"
          effect="dark"
          :content="item.label"
        >
          <el-image
            style="width: 100%; height: 100%"
            :src="item.imageSrc"
            :fit="'fill'"
          ></el-image>
        </el-tooltip>
      </div>
    </div>
    <el-tooltip
      :content="boxMinimize ? $t('show') : $t('hide')"
      placement="top"
      effect="dark"
      v-if="
        operatingStyle == 'largeOperating' || operatingStyle == 'hideOperating'
      "
    >
      <div class="controlButton" @click.stop="changeBoxSize">
        <span
          class="iconfont_Me iconStyle"
          :class="boxMinimize ? 'icon-up' : 'icon-down'"
        ></span>
      </div>
    </el-tooltip>
  </div>
</template>
<script>
import * as RESTApi from "@/api/rest";
import { getBrowserInfo } from "@/utils";
import {
  hangUpTheCall,
  startSharingScreen,
  stopSharingScreen,
  playMeetingVideo,
  stopMeetingVideo,
  startMeetingAudio,
  stopMeetingAudio,
  getMeetingDevice,
  judgeHardwareOccupy,
} from "../coreOperations/local";
export default {
  props: {
    screenSizeForMeeting: {
      type: String,
      default: "largeScreen",
    },
  },
  data() {
    return {
      // 操作条是否最小化
      boxMinimize: false,
    };
  },
  mounted() {},
  computed: {
    meetingDeviceList() {
      return this.$store.state.multimedia_meetingmode.meetingDeviceList;
    },
    // 本地音频输入设备列表
    audioInList() {
      return this.meetingDeviceList.audioIn &&
        this.meetingDeviceList.audioIn.length != 0
        ? true
        : false;
    },
    // 本地视频输入设备列表
    videoList() {
      return this.meetingDeviceList.video &&
        this.meetingDeviceList.video.length != 0
        ? true
        : false;
    },
    // 是否展示切换摄像头按钮（分享屏幕的情况下不展示）
    showCameraStatusButton() {
      return this.$store.state.multimedia_meetingmode.showCameraStatusButton;
    },
    showChatButton() {
      return this.$store.state.multimedia_meetingmode.showChatStatus;
    },
    // 当前未读消息数
    unread() {
      return this.$store.state.multimedia_meetingmode.unread > 99
        ? "99+"
        : this.$store.state.multimedia_meetingmode.unread;
    },
    // 判断是否需要隐藏这个操作窗，这个功能只有在小屏幕浏览器才会触发
    needToHide() {
      return (
        this.$store.state.multimedia_meetingmode.meetingWindowStyle ===
          "normalStyle" &&
        this.screenSizeForMeeting === "smallScreen" &&
        this.$store.state.multimedia_meetingmode.needToHideOperationButton &&
        (this.$store.state.multimedia_meetingmode.userSetCameraStatus ||
          this.$store.state.multimedia_meetingmode.isSharingScreen)
      );
    },
    // 播放窗口是否是普通大小状态
    meetingWindowIsNormal() {
      return this.$store.state.multimedia_meetingmode.meetingWindowStyle ===
        "largestStyle"
        ? false
        : true;
    },
    // 本地用户是否进入分享屏幕状态
    isSharingScreen() {
      return this.$store.state.multimedia_meetingmode.isSharingScreen;
    },

    // 用户设置的摄像头状态
    userSetCameraStatus() {
      return this.$store.state.multimedia_meetingmode.userSetCameraStatus;
    },
    // 用户设置的麦克风状态
    userSetMicrophoneStatus() {
      return this.$store.state.multimedia_meetingmode.userSetMicrophoneStatus;
    },
    // 摄像头授权状态
    cameraIsAuthorized() {
      return this.$store.state.multimedia_meetingmode.cameraIsAuthorized;
    },
    // 麦克风授权状态
    microphoneIsAuthorized() {
      return this.$store.state.multimedia_meetingmode.microphoneIsAuthorized;
    },
    // 摄像头按钮图片
    cameraImage() {
      return this.userSetCameraStatus
        ? require("../../../../assets/images/newMeetingMultiplayer/camera_open.png")
        : !this.cameraIsAuthorized || !this.videoList
        ? require("../../../../assets/images/newMeetingMultiplayer/cameraIsNotAuthorized.png")
        : require("../../../../assets/images/newMeetingMultiplayer/camera_close.png");
    },
    // 麦克风按钮图片
    microphoneImage() {
      return this.userSetMicrophoneStatus
        ? require("../../../../assets/images/newMeetingMultiplayer/microphone_open.png")
        : !this.microphoneIsAuthorized || !this.audioInList
        ? require("../../../../assets/images/newMeetingMultiplayer/microphoneIsNotAuthorized.png")
        : require("../../../../assets/images/newMeetingMultiplayer/microphone_close.png");
    },
    // 操作按钮集合
    operatingButtons() {
      let operatingButtons = [
        {
          imageSrc: this.microphoneImage,
          value: "microphone",
          label: this.$t("microphone"),
        },
        {
          imageSrc: this.cameraImage,
          value: "camera",
          label: this.$t("camera"),
        },
        {
          imageSrc: this.isSharingScreen
            ? require("../../../../assets/images/newMeetingMultiplayer/quitShare.png")
            : require("../../../../assets/images/newMeetingMultiplayer/startShare.png"),
          value: "shareScreen",
          label: this.$t("shareScreen"),
        },
        {
          imageSrc: require("../../../../assets/images/newMeetingMultiplayer/link.png"),
          value: "link",
          label: this.$t("inviteFriend"),
        },
        {
          imageSrc: this.showChatButton
            ? require("../../../../assets/images/newMeetingMultiplayer/chat_close.png")
            : require("../../../../assets/images/newMeetingMultiplayer/chat.png"),
          value: "showChat",
          label: this.$t("meetingChat"),
        },
        {
          imageSrc: require("../../../../assets/images/newMeetingMultiplayer/hangUp.png"),
          value: "hangUp",
          label: this.$t("hangUpMeeting"),
        },
      ];

      operatingButtons = operatingButtons.map((button, index) => {
        switch (this.operatingStyle) {
          case "largeOperating":
            if (button.value == "hangUp") {
              button.top = 14.5;
              button.left = 26 + index * 70;
            } else {
              button.top = 15;
              button.left = 22 + index * 70;
            }
            break;
          case "commonOperating":
            if (button.value == "hangUp") {
              button.top = 3.5;
              button.left = 4 + index * 70;
            } else {
              button.top = 4;
              button.left = index * 70;
            }
            break;
          case "miniOperating":
            if (index >= 4) {
              button.top = 65;
              button.left = (index - 3) * 70;
            } else {
              button.top = 0;
              button.left = index * 70;
            }
            break;
        }
        return button;
      });
      return operatingButtons;
    },
    // 操作条宽度
    operatingWidth() {
      let operatingWidth = 0;
      switch (this.operatingStyle) {
        case "largeOperating":
          operatingWidth = 502;
          break;
        case "commonOperating":
          operatingWidth = 405;
          break;
        case "miniOperating":
          operatingWidth = 255;
          break;
        case "hideOperating":
          operatingWidth = 117;
          break;
      }
      return operatingWidth;
    },
    // 根据不同窗口展示不同样式
    operatingStyle() {
      let operatingStyle = "commonOperating";
      if (this.meetingWindowIsNormal) {
        operatingStyle =
          this.screenSizeForMeeting === "largeScreen"
            ? "commonOperating"
            : "miniOperating";
      } else if (this.boxMinimize) {
        operatingStyle = "hideOperating";
      } else {
        operatingStyle = "largeOperating";
      }
      return operatingStyle;
    },
    // 当前在主屏展示的人员ID，如果是null的话，就是展示的当前用户自己
    membersOnTheHomeScreen() {
      return this.$store.state.multimedia_meetingmode.membersOnTheHomeScreen;
    },
    // 主屏幕dom对象
    homeScreenElement() {
      return this.$store.state.multimedia_meetingmode.homeScreenElement;
    },
  },
  watch: {
    // 监听屏幕分享开启的状态
    isSharingScreen: {
      handler(val, old) {
        // 如果当前主屏播放的是本地流，才会执行后续操作
        if (this.membersOnTheHomeScreen !== null) {
          return;
        }
        if (val !== old && val == false) {
          stopSharingScreen(this.homeScreenElement)
            .then(async (e) => {
              //  如果摄像头打开的状态，退出了屏幕分享，则把视频流放到主屏去播放。
              if (this.userSetCameraStatus) {
                stopMeetingVideo(document.getElementById("localVideo"))
                  .then((e) => {
                    playMeetingVideo(this.homeScreenElement)
                      .then((e) => {
                        this.$store.commit("setUserSetCameraStatus", true);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
              this.$store.commit("setShowCameraStatusButton", true);
              let params = {
                meetingType: "groupChat",
                subjectId: this.$store.state.multimedia_meetingmode
                  .meetingGroupId,
                openCamera: this.$store.state.multimedia_meetingmode
                  .userSetCameraStatus,
              };
              // 屏幕分享关闭成功后发起请求
              let result = await RESTApi.turnOffShareScreen(params);
              if (result.code !== 200) {
                this.$message({
                  type: "error",
                  message: result.message,
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else if (val !== old && val == true) {
          startSharingScreen(this.homeScreenElement)
            .then(async (e) => {
              this.$store.commit("setShowCameraStatusButton", false);
              // 如果主屏幕在播放视频，则先关闭视频
              if (this.userSetCameraStatus) {
                stopMeetingVideo(this.homeScreenElement)
                  .then((e) => {
                    playMeetingVideo(document.getElementById("localVideo"))
                      .then((e) => {
                        this.$store.commit("setUserSetCameraStatus", true);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }

              // 开始分享就最小化
              let params = {
                meetingType: "groupChat",
                subjectId: this.$store.state.multimedia_meetingmode
                  .meetingGroupId,
              };
              // 屏幕分享打开成功后发起请求
              let result = await RESTApi.shareScreen(params);
              if (result.code !== 200) {
                this.$message({
                  type: "error",
                  message: result.message,
                });
              }
            })
            .catch((e) => {
              console.log(e);
              // this.$message({
              //   type: "error",
              //   message: this.$t("browserlimit"),
              // });
            });
        }
      },
      deep: true,
    },

    // 监听视频是否打开的状态
    "$store.state.multimedia_meetingmode.meetingWindowStyle": {
      handler(val, old) {
        let objScreenElement = this.isSharingScreen
          ? document.getElementById("localVideo")
          : this.homeScreenElement;
        // 如果当前主屏播放的是本地流，才会执行后续操作
        if (this.membersOnTheHomeScreen) {
          objScreenElement = document.getElementById("localVideo");
        }
        if (val == "smallStyle" && this.userSetCameraStatus) {
          stopMeetingVideo(objScreenElement)
            .then((e) => {
              playMeetingVideo(document.getElementById("miniScreen"))
                .then((e) => {
                  // this.$store.commit("setIsSharingScreen", false);
                  this.$store.commit("setUserSetCameraStatus", true);
                })
                .catch((e) => {
                  console.log(e);
                });
            })
            .catch((e) => {
              console.log(e);
            });
        } else if (val != "smallStyle" && this.userSetCameraStatus) {
          stopMeetingVideo(objScreenElement)
            .then((e) => {
              playMeetingVideo(objScreenElement)
                .then((e) => {
                  // this.$store.commit("setIsSharingScreen", false);
                  this.$store.commit("setUserSetCameraStatus", true);
                })
                .catch((e) => {
                  console.log(e);
                });
            })
            .catch((e) => {
              console.log(e);
            });
        }
      },
    },
  },
  methods: {
    // 全屏时收起操作条
    changeBoxSize() {
      this.boxMinimize = !this.boxMinimize;
    },
    // 点击操作按钮
    clickOperating(value) {
      switch (value) {
        // 设置摄像头开启状态
        case "camera":
          this.switchCameraStatus();
          break;
        // 设置麦克风开启状态
        case "microphone":
          this.switchMicrophoneStatus();
          break;
        // 改变会议窗口大小
        case "size":
          this.$store.commit(
            "setMeetingWindowStyle",
            this.meetingWindowIsNormal ? "largestStyle" : "normalStyle"
          );
          break;
        // 将会议窗口缩为最小，并悬浮在浏览器右上角
        case "hanging":
          this.$store.commit(
            "setMeetingWindowStyle",
            this.$store.state.multimedia_meetingmode.meetingWindowStyle !==
              "smallStyle"
              ? "smallStyle"
              : "normalStyle"
          );
          this.switchCameraStatus("miniScreen");
          break;
        // 点击分享屏幕按钮
        case "shareScreen":
          this.toggleShareScreen();
          break;
        // 打开分享会议链接弹窗
        case "link":
          this.$store.commit("setShareMeetingWindow", true);
          break;
        case "showChat":
          this.switchShowChatStatus();
          break;
      }
    },
    // 切换摄像头状态
    switchCameraStatus() {
      if (!this.$store.state.multimedia_meetingmode.localStreamIsReady) {
        console.log("本地视频流未准备好");
        // return;
      }
      // 通过原生检查硬件给用户错误提示
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;
      if (navigator.getUserMedia) {
        let that = this;
        navigator.getUserMedia(
          { video: true },
          function onSuccess(stream) {},
          function onError(error) {
            console.log("摄像头原生程序硬件检测错误：", error);
            if (String(error).indexOf("Permission denied") != -1) {
            } else {
              that.$message({
                type: "error",
                message: that.$t("noCamera"),
              });
            }
          }
        );
      }

      // 刷新硬件
      getMeetingDevice()
        .then((e) => {
          // 检测摄像头是否被占用
          judgeHardwareOccupy("video")
            .then((e) => {
              // 这是判断本地硬件是否能用
              if (this.$store.state.multimedia_meetingmode.localCameraStatus) {
                // 如果当前就是开启状态的话，就关闭摄像头
                switch (this.userSetCameraStatus) {
                  case true:
                    console.log("stopMeetingVideo");
                    stopMeetingVideo()
                      .then((e) => {
                        this.$store.commit("setUserSetCameraStatus", false);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                    break;
                  case false:
                    const obScreen = this.isSharingScreen
                      ? document.getElementById("localVideo")
                      : this.homeScreenElement;
                    playMeetingVideo(obScreen, "video")
                      .then((e) => {
                        // this.$store.commit("setIsSharingScreen", false);
                        this.$store.commit("setUserSetCameraStatus", true);
                      })
                      .catch((e) => {
                        console.log(e);
                      });

                    break;
                }
              } else {
                console.log("本地没有摄像头支持");
              }
            })
            .catch((err) => {})
            .finally(() => {
              if (!this.cameraIsAuthorized) {
                this.$store.commit("setEnablePermissionsDialog", {
                  visible: true,
                  type: 1,
                });
                return;
              }
            });
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err,
          });
        });
    },
    // 切换麦克风状态
    switchMicrophoneStatus() {
      // 原生检查硬件给用户错误提示
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;
      if (navigator.getUserMedia) {
        let that = this;
        navigator.getUserMedia(
          { audio: true },
          function onSuccess(stream) {},
          function onError(error) {
            console.log("麦克风原生程序硬件检测错误：", error);
            if (String(error).indexOf("Requested device not found") != -1) {
              that.$message({
                type: "error",
                message: that.$t("noAudio"),
              });
            }
            if (String(error).indexOf("Permission denied") != -1) {
            }
          }
        );
      }
      // 刷新硬件
      getMeetingDevice()
        .then((e) => {
          // 判断本地硬件是否被占用，并设定硬件状态
          judgeHardwareOccupy("audio")
            .then((e) => {
              if (
                this.$store.state.multimedia_meetingmode.localMicrophoneStatus
              ) {
                switch (this.userSetMicrophoneStatus) {
                  case true:
                    stopMeetingAudio()
                      .then((e) => {
                        this.$store.commit("setUserSetMicrophoneStatus", false);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                    break;
                  case false:
                    startMeetingAudio()
                      .then((e) => {
                        this.$store.commit("setUserSetMicrophoneStatus", true);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                    break;
                }
              } else {
                console.log("本地没有麦克风");
              }
            })
            .catch((err) => {})
            .finally(() => {
              if (!this.microphoneIsAuthorized) {
                this.$store.commit("setEnablePermissionsDialog", {
                  visible: true,
                  type: 2,
                });
              }
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换开启屏幕分享
    toggleShareScreen() {
      // if (!this.$store.state.multimedia_meetingmode.localStreamIsReady) {
      //   console.log("本地视频流未准备好");
      //   return;
      // }
      let browser = getBrowserInfo();
      let b_name = (browser + "").replace(/[0-9./]/gi, "");
      let b_version = parseInt((browser + "").replace(/[^0-9.]/gi, ""));
      if (
        (b_name.indexOf("chrome") > -1 && b_version > 74) ||
        (b_name.indexOf("safari") > -1 && b_version > 12) ||
        (b_name.indexOf("firefox") > -1 && b_version > 74)
      ) {
        this.$store.commit("setIsSharingScreen", !this.isSharingScreen);
      } else {
        this.$store.commit("setNotSupportSharingScreenDialog", true);
      }
    },
    // 点击挂断按钮，中断通讯
    async hangupTheCall() {
      //清空会议的聊天记录
      this.$store.commit("clearMessageAll");
      this.$store.commit("setMeetingUnread", 0);
      if (!this.$store.state.multimedia_meetingmode.localStreamIsReady) {
        console.log("本地流未准备好,将强制挂断");
      }

      // 先通知后台再挂断网易云信
      let params = {
        chatType: "groupChat",
        subjectId: this.$store.state.multimedia_meetingmode.meetingGroupId,
      };
      let result = await RESTApi.exitMeeting(params);
      if (result.code == 200) {
        hangUpTheCall()
          .then(async (e) => {
            // 处理完数据后，将窗口关闭
            this.$store.commit("setMeetingWindowStatus", false);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 切换开启聊天
    switchShowChatStatus() {
      this.$store.commit(
        "setShowChatStatus",
        !this.$store.state.multimedia_meetingmode.showChatStatus
      );
      if (this.$store.state.multimedia_meetingmode.showChatStatus) {
        this.$store.commit("setMeetingUnread", 0);
        this.$store.commit("setMeetingWindowStyle", "normalStyle");
        this.chatWithAuthor(
          { id: this.$store.state.multimedia_meetingmode.meetingGroupId },
          "group"
        );
        this.$store.commit("setShowChatStatus", false);
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.operatingBox {
  left: 50%;
  width: 100%;
  z-index: 3;
  position: absolute;
  transform: translate3d(-50%, 0, 0);
  transition: opacity 0.5s ,height 0.2s;
  .buttonsBox {
    min-width: 0;
    height: 100%;
    position: relative;
    .buttonStyle {
      border-radius: 50%;
      user-select: none;
      position: absolute;
      transition: top 0.5s ,left 0.2s;
      .unread {
        width: 10px;
        height: 10px;
        background: #f56c6c;
        position: absolute;
        top: 5px;
        right: 6px;
        z-index: 1;
        color: white;
        border-radius: 50%;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.hideOperating {
  bottom: 0px;
  height: 24px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  background: #3C3636;
  display: flex;
  align-items: center;
  justify-content: center;

  .buttonsBox {
    display: none;
  }

  .controlButton {
    .iconStyle{
      color: white;
      cursor pointer;
      font-size: 20px;
      font-weight: 700;
    }
  }

}

.largeOperating {
  bottom: 16px;
  height: 75px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 10px;
  background: rgba(40, 40, 40, 0.95);

  .buttonsBox {
    width: 427px
  }
  .controlButton {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 440px;
    transform: translate3d( 0, -50%, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    .iconStyle{
      color: white;
      cursor pointer;
      font-size: 20px;
      font-weight: 700;
    }
  }
}

.miniOperating {
  bottom: 30px;
  height: 110px;
  width: 100%;

  .buttonsBox {
    width: 100%;
  }
}

.commonOperating {
  bottom: 40px;
  height: 53px;

  .buttonsBox {
    width: 100%;
  }
}
</style>
