<template>
  <div class="joinTheMeetingTips" v-if="visible" :key="joinMeetingMemberInfo.name">
    <div class="nickname">
      {{joinMeetingMemberInfo.name}}
    </div>
    <div class="joinIcon">
      <i class="iconfont_Me icon-plus iconStyle"></i>
    </div>
    <div class="meetingIcon">
      <i class="iconfont_Me icon-video-fill iconStyle"></i>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 当前加入会议的成员信息
    joinMeetingMemberInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      // 定时器对象
      timer: null,
      // 自动关闭时间
      autoClose: 3000,
      // 组件状态展示
      visible: true
    }
  },
  mounted() {
    this.createTimer();
  },
  beforeDestroy() {
    this.clearTimer();
  },
  methods: {
    // 开始计时
    createTimer() {
      if(this.autoClose) {
        this.timer = setTimeout(()=>{
          this.$emit("deleteJoinTips",this.joinMeetingMemberInfo.uid)
        },this.autoClose)
      }
    },
    // 清除定时器
    clearTimer() {
      if(this.timer) {
        clearTimeout(this.timer)
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
  .joinTheMeetingTips
    max-width 135px;
    height 32px;
    overflow hidden;
    border-radius 16px;
    background #43D87F;
    padding 0 10px;
    margin-bottom 10px;
    display flex;
    align-items center;
    justify-content flex-start;
    .nickname
      max-width 100%;
      min-width 0;
      height 100%;
      overflow hidden;
      text-overflow ellipsis;
      white-space nowrap;
      font-size 13px;
      color #FFFFFF;
      line-height 32px;
      text-align left;
    .joinIcon
      height 100%;
      width 13px;
      flex-shrink 0;
      display flex;
      align-items center;
      justify-content center;
      margin 0 3px;
      .iconStyle
        color #FFFFFF
        font-size 13px;
    .meetingIcon
      width 23px;
      height 100%;
      flex-shrink 0;
      display flex;
      align-items center;
      justify-content center;
      .iconStyle
        color #FFFFFF
        font-size 20px;

</style>